@import "../../../css/constants.scss";
apps-table {
  .user_selecting-wrapper  {
    position: relative;
    padding: 5px 27px;
    border: 1px solid #0D99FF;
    border-radius: 23px;
    margin-top: 49px;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      color: $black;
      font-size: 14px;
      &:hover {
        border-color: transparent!important;
      }
      &:focus {
        border-color: transparent!important;
      }
    }
    .search {
      margin-right: 7px;
    }
    .close {
      cursor: pointer;
      position: absolute;
      right: 15px;
    }
  }
  .app_icon {
    margin-right: 8px;
  }
  .app_name {
    color: $black;
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
  }
  .add_member_wrapper {
    display: flex;
    justify-content: center;
    .add_member {
      margin-top: 49px;
      font-size: 26px;
      span {
        margin-right: 5px;
      }
    }
  }
  
  .listing_table {
    min-width: 688px;
    margin-top: 28px;
      .listing_table_head_wrapper {
        border-bottom: 2px solid $blue;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .listing_table_body_wrapper {
        max-height: 500px;
        overflow-y: auto;
        min-height: 400px;
        .listing_table_nodata {
          min-height: 500px;
          width: 100%;
          background-color: rgba(232, 239, 244, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            color: rgba(0, 0, 0, 0.2);
            font-size: 60px;
            font-weight: 400;
            line-height: 73px;
          }
        }
      }
      .listing_table_column {
        .number {
          margin-right: 25px;
          color: $black;
        }
        .name {
          color: $black;
          font-size: 16px;
          font-weight: 300;
        }
        &.w-50 {
          width: 50%;
          display: flex;
          align-items: center;
        }
        &.w-35 {
          width: 35%;
          text-align: left;
        }
        &.w-15 {
          width: 15%;
          text-align: right;
        }
      }
      .listing_table_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 11px;
        .delete_icon {
          opacity: 0;
        }
        &:hover {
          background-color: rgba(13, 153, 255, 0.08);
          .delete_icon {
            cursor: pointer;
            opacity: 1;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid $light-grey;
        }
      }
      .tab {
        color: $blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
  }
}
.delete_group-popup {
  padding: 0 110px;
  max-width: 658px;
  .dialog-share-group__title {
    margin-bottom: 41px;
    color: $black;
    font-size: 30px;
    line-height: 35px;
  }
  .info {
    color: $black;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }
}