@import "./../../../css/css3-mixins.scss";
@import "./../../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* ----------------------------------------------- GH-TEXT-EDITOR --------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-text-editor {
    display: block;
    position: relative;
    transition: 0.3s ease-out all;

    &.editorTrue{
        border-left: 2px solid #d9e0e3;
        padding-left: 10px;
        margin-top: 1em;

        &:hover {
            border-left: 2px solid rgba(8, 147, 210, 1);
            cursor: pointer;

            div.contentPage {
                border: 1px solid $blue;
            }
        }
    }

    & > div.mce-tinymce.mce-container.mce-panel {
        border: 0;
    }

    table {
        td {
            vertical-align: top;
        }
    }

    div.contentPage {
        font-weight: 500;
        border: 1px solid $white;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
    }
}


/* -------------------------- TEXT EDITOR ----------------------------------- */
gh-text-editor {
  display: block;
  position: relative;
}

/* ------------------------------ TAG --------------------------------------- */
gh-tag {
  display: block;
  position: relative;
}

.gudhub-wysiwyg-editor{
  position: relative;
  width: 100%;

  [document-template-id]{
    &.editable{
      @include background-hex($light-grey);
      //@include user-select('none');
    }

    padding: 15px 20px;

    .template{
      @include background-hex($white);
      box-shadow:0 0 5px 0 rgba(0,0,0,0.15);
      @include user-select('text');
      word-break: break-all;
      cursor: text;

      table{
        width: 100%;
        table-layout: fixed;
        min-height: 18px;
        border-collapse: separate; /* Or do nothing, this is default */
        border-spacing: 2px;

        td, th{
          border: 1px dotted black;
        }
      }

      .column{
        display: block;
        width: 100%;
        border: 1px dotted black;

        & > div{
          display: inline-block;
          width: 50%;
          float: left;
          min-height: 18px;

          &:not(:last-child){
            border-right: 1px dotted black;
          }
        }

        &:after{
          visibility: hidden;
          display: block;
          font-size: 0;
          content: " ";
          clear: both;
          height: 0;
        }
      }

      b{
        font-weight: bold;
      }
    }

    .interpritator{

      background-color: rgb(247, 216, 216);
      border:1px solid rgba(255, 0, 0, 0.4);
      margin:0 1px;
      padding:0 1px;
      line-height: 1;
      border-radius: 5px;

      //@include user-select('none');
      //content: attr(data-field); /* no quotes around attribute name! */

      display: inline-block;

    }

    p{
      display: block;

    }

  }


  // ------------------------ Choise application form -------------------------
  .app-form{
    width: 100%;
    max-width: 320px;
    height: 500px;
    box-shadow: 0 0 6px rgba(0, 20, 70, 0.3);
    overflow: hidden;
    margin-top: 1em;

    .step-1{
      .rocket{
        text-align: center;
        padding: 80px 0 30px 0;
      }

      .step-title{
        font-size: 40px;
        color: $dark-grey;
        padding-bottom: 200px;
        text-align: center;
        @include transition('color 0.3s ease');
      }

      &:hover{
        .step-title{
          color: $blue;
        }
      }
    }

    .step-2{
      padding: 0 15px;
      .step-title{
        font-size: 22px;
        color: $blue;
        text-align: center;
        padding-top: 35px;
      }

      .app-select{
        justify-content: center;
        padding-top: 17px;

        span.field-name{
          min-width: 35px;
          max-width: 35px;
        }

        md-autocomplete{
          min-width: inherit;
          // width: 14.29em;
        }
      }

      .app{
        height: 300px;
        padding-top: 35px;

        .app-icon{
          width: 160px;
          margin: 0 auto;

          &.default{
            border-radius: 25%;
            border: 1px solid $dark-grey;
          }
        }

        .app-name{
          font-size: 24px;
          text-align: center;
          padding-top: 5px;
        }
      }

      .controls-panel-btn{
        text-align: center;

        .control-btn{
          font-size: 18px;
          color: $blue;
          border: 1px solid $blue;
          display: inline-block;
          padding: 5px 18px;
          border-radius: 100px;
          cursor: pointer;
          margin: 0 10px;

          &:hover{
            background-color: $blue;
            color: $white;
          }
        }
      }


    }

  }

}