@import "css3-mixins";
@import "constants.scss";

/* -------------------------------------------------------------------------------------------------------------------*/
/* ------------------------------------------------ MAIN STYLES ------------------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/
.gudhub_overflow{
    overflow: hidden;
}

body {
    line-height: normal;
    &.gudhub {
        line-height: normal;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 14px;
        height: 100%;
        width: 100%;
        // background: rgb(105,155,200);
        // background: -moz-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%, rgba(181,197,216,1) 57%);
        // background: -webkit-gradient(radial, top left, 0px, top left, 100%, color-stop(0%,rgba(105,155,200,1)), color-stop(57%,rgba(181,197,216,1)));
        // background: -webkit-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
        // background: -o-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
        // background: -ms-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
        // background: radial-gradient(ellipse at top left, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#699bc8', endColorstr='#b5c5d8',GradientType=1 );
        @include background-size( 'cover');
        /* ------------- PLACEHOLDER STYLE ------------- */
        :-moz-placeholder,
        :-ms-input-placeholder,
        ::-moz-placeholder,
        ::-webkit-input-placeholder {
            color: $light-grey;
        }

        // h1 {
        //     font-size: 30px;
        //     margin-top: 0;
        //     line-height: 35px;
        //     font-weight: 300;
        // }

        // h2 {
        //     font-size: 22px;
        //     margin-top: 0;
        //     font-weight: 300;
        // }

        // h3 {
        //     font-size: 20px;
        //     margin-top: 0;
        // }

        // h4 {
        //     font-size: 18px;
        // }

        // h5 {
        //     font-size: 16px;
        // }

        // h6 {
        //     font-size: 14px;
        // }

        a img {
            border: none;
        }

        svg {
            display: inline-block;
        }

        input {
            &:not(.disable-gudhub-styles) {
                border-radius: 0px!important;
                border-style: solid;
                font-family: inherit;
            }
        }

        section::after,
        section::before {
            position: absolute;
            content: '';
            pointer-post-wraps: none;
        }

        .gh-wrap-container {
            position: relative;
            z-index: 0;
            overflow-x: hidden;
            padding-bottom: 0 !important;
            min-height: 100vh;
            max-height: 100vh;
            height: 100vh;
            // overflow-y: scroll; ??
            -webkit-overflow-scrolling: touch;

            .gh-wrapper-view {
                height: 100%;
                min-height: 100%;
                z-index: 90;
            }
        }

        .container {
            width: 1170px;
            margin: auto;

            .subtitle {
                width: 60%;
                margin-bottom: 20px;
                font-size: 16px;
            }
        }
        @media screen and (max-width: 1200px) {
            .container {
                width: 970px;
            }
        }
        @media screen and (max-width: 992px) {
            .container {
                width: 750px;
            }
        }
        @media screen and (max-width: 768px) {
            .container {
                width: auto;
            }

            .wrap-apps-list .rubbish {
                display: none;
            }
            .wrap-apps-list .btn-slider-show {
                display: none;
            }
        }

        .navbar {
            background: none;
            border: 0 none;
            border-radius: 0;
            margin: 0;
            left: 0;
            top: 0;
            height: 87px;
            width: 100%;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            .app_icon{
                z-index: 9;
                margin-left: 40px;
                padding-top: 20px;
            }
            .navbar-header {
                float: none;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .navbar-nav {
                & > li {
                    &:first-child {
                        a {
                            &.btn-login {
                                border-right: 1px solid #fff;
                            }
                        }
                    }

                    & > a {
                        padding: 0;
                    }
                }

                &.homePage {
                    & > li {
                        & > a {
                            color: #000;
                            border: 0;
                            border-right: 1px solid #0893d2;
                            font-weight: 300 !important;
                            margin: 23px 0;
                            padding: 3px 17px;
                            font-size: 16px !important;
                            line-height: 16px !important;
                            border-radius: 0 !important;
                        }
                        &:last-child {
                            a{
                                border-right: none;
                            }
                        } 
                    }
                }
            }

            .navbar-toggle {
                border: none;
                margin: 20px 20px 0 0;

                &:focus {
                    transform: none;
                    background-color: transparent;
                }

                &:hover {
                    background-color: transparent;
                    transform: scale(1.2);
                }
            }

            .icon-bar {
                background-color: #fff;
                height: 2px;
                width: 30px;
                margin-top: 10px;

                &:first-child {
                    margin: 0;
                }
            }
        }

        .navbar-brand {
            display: inline-block;
            color: #fff;
            text-shadow: none;
            font-weight: 100;
            font-size: 30px;
            line-height: 28px;
            height: 70px !important;
            padding: 0;
            text-transform: uppercase;
            margin-top: 10px;
            margin-left: 15px;

            &:active,
            &:focus,
            &:hover {
                color: $white;
            }
        }

        .gh-navbar {
            float: right;
            position: relative;
            z-index: 99;
            cursor: pointer;
        }

        .gh-dropdown-menu {
            min-width: 160px;
        }

        .gh-home-link {
            display: inline-block;
        }

        .gh-home-link a {
            text-decoration: none;
        }

        .gh-navbar.nav.navbar-nav.navbar-right.homePage .gh-home-link .gh-home__link {
            margin: 23px 0;
            display: inline-block!important;
        }

        .gh-navbar.nav.navbar-nav.navbar-right.homePage .gh-home-link .gh-home__link:hover {
            color: $blue;
        }

        .gh-dropdown-menu__link {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: 400;
            line-height: 1.42857143;
            color: #333;
            white-space: nowrap;
            text-decoration: none;
        }

        .dropdown-menu.gh-open_drop {
            display: block;
        }

        .gh-divider {
            height: 1px!important;
            min-height: 1px!important;
            padding: 0;
            margin: 0;
            overflow: hidden;
            background-color: #e5e5e5;
        }

        .navbar-default {
            .navbar-nav {
                & > li {
                    & > a {
                        &:active,
                        &:focus,
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }

        .navbar-inverse {
            .navbar-toggle {
                &:active,
                &:focus,
                &:hover {
                    background-color: #5b90bf;
                }
            }

            .navbar-collapse,
            .navbar-form {
                border: 0 none;
            }

            .navbar-nav {
                & > .active,
                & > .open {
                    & > a {
                        background: #5b90bf;
                    }
                }
            }
        }

        p,
        span,
        textarea {
            &::-moz-selection {
                color: $white;
                text-shadow: none;
                background: $light-blue;
            }

            &::selection {
                color: $white;
                text-shadow: none;
                background: $light-blue;
            }
        }
        /* ---------------------------------------------------------------------------------------------------------------*/
        /* --------------------------------------------------- INPUTS ----------------------------------------------------*/
        /* ---------------------------------------------------------------------------------------------------------------*/
        /* -- Disabled yellow background in autofill inputs -- */
        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px rgb(95, 138, 163) inset;
        }

        // input {
        //     height: 100%;
        // }

        
        input,
        textarea {
            &:not(.disable-gudhub-styles) {
                line-height: normal;
                background-color: transparent;
                border-color: transparent;//!!! Temorary solution, see how it goes
                border-width: 0 0 1px 0;
                border-radius: 0;
                padding-bottom: 5px;
                @extend .transition;
                box-shadow: none!important;

                &:hover {
                    border-color: inherit;
                }

                &:focus {
                    @include box-shadow(0px, 0px, 10px, rgba(0, 0, 0, 0.1));
                    border-color: inherit;
                    padding-bottom: 5px;
                    border-width: 0 0 1px 0;
                }
            }
        }

        .dropdown {
            position: relative;

            input[type=checkbox] {
                display: none;
                /*
        &:checked ~ ul{
          display: block;
        }
        */
            }

            ul {
                position: absolute;
                top: 100%;
                left: 10px;
                z-index: 99;
                display: none;
                @include background-hex($white);
                padding: 5px 0;
                border: 1px solid $grey;
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
                -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

                &.gh-dropd_container--fixed{
                    position: fixed;
                }

                li {
                    display: block;
                    position: relative;
                    padding: 5px 10px;
                    color: rgba(0,0,0,0.6);
                    cursor: pointer;

                    &.triangle {
                        position: absolute;
                        top: -14px;
                        padding: 0;
                        left: 10px;
                    }

                    &:not(.triangle):hover {
                        @include background-hex($blue, 0.2);
                    }

                    div.action {
                        padding: 0;
                        margin: 0;
                    }
                }

                ul {
                    top: 0;
                    left: 100%;

                    li.triangle {
                        display: none;
                    }
                }
            }

            &:hover {
                & > ul {
                    display: block;
                }
            }
        }
        .icon_wrapper {
            display: inline-block;
            position: relative;
            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

body {
    gh-element-container{
        font-size: 14px;
        line-height: normal;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        
         input,
         textarea,input[type^="text"],input[type^="search"]{
            &:not(.disable-gudhub-styles) {
                line-height: normal;
                background-color: transparent;
                border-color: transparent;//!!! Temorary solution, see how it goes
                border-width: 0 0 1px 0;
                border-radius: 0;
                padding-bottom: 5px;
                @extend .transition;
                box-shadow: none!important;
                padding: 10px 5px 5px;
    
                &:focus {
                    @include box-shadow(0px, 0px, 10px, rgba(0, 0, 0, 0.1));
                    border-color: inherit;
                    padding-bottom: 5px;
                    border-width: 0 0 1px 0;
                }
            }
         }
     }
 }

.dropdown-menu {
    & > li {
        & > a {
            transition: none !important;
            cursor: pointer;

            &:focus,
            &:hover {
                background-color: transparent!important;
            }
        }
    }
}
/*MULIDROPDOWNS*/
.dropdown-submenu {
    position: relative;

    &.pull-left {
        float: none;

        & > .dropdown-menu {
            left: -100%;
            margin-left: 10px;
            -webkit-border-radius: 6px 0 6px 6px;
            -moz-border-radius: 6px 0 6px 6px;
            border-radius: 6px 0 6px 6px;
        }
    }

    & > a {
        &:after {
            display: block;
            content: " ";
            float: right;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 0 5px 5px;
            border-left-color: #ccc;
            margin-top: 10px;
            margin-right: -10px;
        }
    }

    & > .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px;
        border-radius: 0 6px 6px 6px;
    }

    &:hover {
        & > .dropdown-menu {
            display: block;
            position: absolute;
            top: 5px;
            left: 100% !important;
        }

        & > a {
            &:after {
                border-left-color: #fff;
            }
        }
    }
}
.gh-app-button {
    padding: 15px 10px;
    width: 100%;
    z-index: 5;
    box-shadow: 0 0 3px rgba(0, 0, 128, 0.2);

    &.open_app {
        box-shadow: none;
    }
}
.gwd-page-size {
    width: 700px;
    height: 700px;
}
/* ------------------------------------------------- HOME PAGE ------------------------------------------------------ */
.gh-home-page {
    min-height: 100%;
    position: relative;
    background-color: $white;

    .homepage_wally_wrapper gh-wally {
        position: absolute;
        right: 0;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 0;
        @media screen and (max-width: 480px) {
            transform: scale(0.6);
            display: none !important;
        }
        @media screen and (max-width: 1280px) {
            transform: scale(0.9);

            .container {
                width: 970px;
            }
        }
        @media screen and (max-width: 992px) {
            .container {
                width: 750px;
            }
        }
        @media screen and (max-width: 768px) {
            .container {
                width: auto;
            }
        }

        .gwd-page-wrapper {
            background-color: rgba(255,255,255,0);
            height: 100%;
            width: 100%;

            .gwd-page-content {
                position: fixed;
                right: 0;
                bottom: 0;
            }
        }
    }

    & > .container {
        height: 100%;
        padding: 15% 25px 0;
        border-radius: 10px;
        z-index: 1;
        position: relative;
    }
}

.gh-page {
    footer {
        height: 60px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        font-weight: 100;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 98;
        padding: 7px 15px;
        display: none;
    }
}

.gh-button-bar {
    background-color: #fff !important;
    box-shadow: 0 0 3px rgba(0, 0, 128, 0.3);
    @include transition( 'none');
}
/*
@media (min-width: 769px)  (max-width: 1280px){
.homepage_wally_wrapper gh-wally{
  transform:scale(0.9);
}
}
*/
.gh-search-block {
    padding: 0 20px 0 30px;
    position: relative;

    .search {
        position: absolute;
        left: 0;
        top: 0;
    }

    input {
        background: none;
        box-shadow: none;
        border: 0 none;
        width: 100%;
        border-bottom: 1px solid #0893d2;
        border-radius: 0;
        font-size: 14px;
        line-height: 16px;
        font-weight: 100;
        padding: 5px 0;
        margin-top: 2px;
    }
}
@media screen and (max-width: 769px) {
    .search-block {
        display: none;
    }
}

.sharingPage {
    width: 95%;
    max-width: 700px;
    margin: 0 auto;
    position: relative;

    .title {
        font-size: 60px;
        font-weight: 200;
        text-align: center;
        padding: 38px 0 41px;
        color: #0893d2;
    }

    .row-count {
        min-width: 30px;
        position: relative;
        text-align: center;

        .tickbox {
            width: 28px;
            height: 28px;
            border: 1px solid #0893d2;
            border-radius: 50%;
            text-align: center;
            display: none;
            cursor: pointer;
            position: absolute;
            top: 0;
            margin-top: -8px;

            .checked {
                margin-top: 5px;
                text-align: center;
            }

            svg {
                display: inline-block;
            }
        }

        &.selected,
        &:hover {
            .tickbox {
                display: block;
            }

            & > span {
                display: none;
            }
        }
    }

    .addUser,
    .deleteUser {
        height: 50px;
    }

    .deleteUser {
        padding: 10px;
        margin-left: 10px;
    }
}

.sharingSearchUser {
    padding: 3px 23px;
    border: 1px solid #0893d2;
    border-radius: 100px;

    input {
        border: 0px!important;
    }
}

.sharingUserlist {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 25px;
    padding: 5px;
    margin-bottom: 20px;

    gh-radio-button li:hover {
        background-color: #fff!important;
        border-color: #0893d2!important;
        color: #0893d2!important;
    }

    & > li {
        border-bottom: 1px solid #EAEAEA;
        padding: 15px 0;

        .user {
            font-size: 16px;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

gh-option-table-new input {
    width: 100%;
    padding: 0;
}

img-crop {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
}

img-crop canvas {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
/* --------- Text center ---------- */
.text-center {
    text-align: center;
}
/* ------------------------------ FLEX--------------------------- */
.flex-initial {
    -webkit-flex: initial;
    -ms-flex: initial;
    flex: initial;
}

.flex {
    @include display-flex();
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    @include flex-align-items( 'stretch');
}

.display-flex {
    @include display-flex();
}

.flex-1 {
    @include flex(1);
}

.flex-0 {
    @include flex(0);
}

.flex-row {
    @include flex-direction( 'row');
}

.flex-column {
    @include flex-direction( 'column');
}

.flex-justify-start {
    @include flex-justify( 'flex-start');
}

.flex-justify-end {
    @include flex-justify( 'flex-end');
}

.flex-justify-center {
    @include flex-justify( 'center');
}

.flex-justify-end {
    @include flex-justify( 'flex-end');
}

.flex-justify-bettween {
    @include flex-justify( 'space-between');
}

.flex-align-items-center {
    @include flex-align-self( 'center');
}

.flex-align-items-stretch {
    @include flex-align-self( 'stretch');
}

.flex-align-content-stretch {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

.flex-aling-self-end {
    @include flex-align-self( 'flex-end');
}

.flex-aling-self-center {
    @include flex-align-self( 'center');
}

.flex-nowrap {
    flex-wrap: nowrap;
}
/* --------- Display block ---------- */
.display-block {
    display: block;
}
/* ------ Display inline-block ------ */
.display-inline-block {
    display: block;
}

.position-relative {
    position: relative;
}
/* -------------------------------------------------------------------------------------------------------------------*/
/* ---------------------------------------------- DEFAULT STYLES -----------------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/
/* --------------- All animation(transition) --------------- */
.transition {
    @include transition( 'all .3s ease-in-out');
}
/* ------------- Disabled animation(transition) ------------ */
.transition-disabled {
    @include transition( 'none');

    * {
        @include transition( 'none');
    }
}
/* -------------------------------------------------------------------------------------------------------------------*/
/* -------------------------------------------------- FONT SIZE ------------------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/
.font28 {
    font-size: 28px;
}

.font42 {
    font-size: 42px;
}
/* -------------------------------------------------------------------------------------------------------------------*/
/* --------------------------------------------------- WALLY LOADER --------------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/
/* ----------------- Wally loader ---------------------*/
.cssload-fond {
    position: relative;
    margin: auto;
    height: 100%;
    width: 100%;
}

.cssload-container-general {
    @include animation(cssload-animball_two 1.75s infinite);
    width: 55px;
    height: 55px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
}

.cssload-internal {
    width: 55px;
    height: 55px;
    position: absolute;
}

.cssload-ballcolor {
    width: 25px;
    height: 25px;
    @include border-radius( '50%');
}

%cssload-ball {
    position: absolute;
    left: 0;
    top: 0;
    @include animation(cssload-animball_one 1.75s infinite ease)
}

.cssload-ball_1 {
    @extend %cssload-ball;
    @include background-hex(#cb2025, 0.82);
}

.cssload-ball_2 {
    @extend %cssload-ball;
    @include background-hex(#f8b334, 0.82);
    left: 30px;
}

.cssload-ball_3 {
    @extend %cssload-ball;
    @include background-hex(#00a096, 0.82);
    top: 30px;
}

.cssload-ball_4 {
    @extend %cssload-ball;
    @include background-hex(#97bf0d, 0.82);
    top: 30px;
    left: 30px;
}

@include keyframes ('cssload-animball_one') {
    0% {
        position: absolute;
    }

    50% {
        top: 15px;
        left: 15px;
        position: absolute;
        @include opacity(0.5);
    }

    100% {}
}

@include keyframes ('cssload-animball_two') {
    0% {
        @include transform( 'rotate(0deg) scale(1)');
    }

    50% {
        @include transform( 'rotate(360deg) scale(1.3)');
    }

    100% {
        @include transform( 'rotate(720deg) scale(1)');
    }
}
/* --------------------- Buttons style -------------------- */
.gh-btn {
    @extend .transition;
    @extend .text-center;
    @include display-flex();
    @include flex-direction( 'column');
    @include flex-justify( 'center');
    @include flex-align-items( 'center');
    position: relative;
    font-weight: 300;
    @include border-radius( '50px');
    padding: 7px 13px;
    line-height: 100%;
    color: $black;
    cursor: pointer;

    & > p {
        display: block;
    }

    .icon {
        display: block;
    }

    &.inline {
        @extend .flex-row;

        .icon {
            display: inline-flex;
            margin-right: 0;
        }

        & > p {
            display: inline-block;
        }
    }
    /* ---------------------*/
    &-blue {
        color: $blue;

        svg path {
            fill: $blue;
        }

        &.gh-btn-bg {
            @include background-hex($blue);
            border: 1px solid $blue;
            color: $white;
            font-weight: 300;

            svg path {
                fill: $white;
            }

            &:hover {
                @include background-hex($white);
                color: $blue;
                // font-weight: 100;

                .icon svg path {
                    fill: $blue;
                }

                svg path {
                    fill: $blue;
                }
            }

            &-reverse {
                @include background-hex($white);
                border: 1px solid $blue;
                color: $blue;

                &:hover {
                    color: $white;
                    @include background-hex($blue);
                }
            }
        }
    }

    &-green {
        @include background-hex($green);
        border-color: $green;

        &-reverse {}
    }

    &-red {
        @include background-hex($red, 0.82);

        &-reverse {}
    }

    &.text-blue {
        color: $blue;
    }

    &.text-dark-gray {
        color: $dark-grey;
    }

    &.text-light-gray {
        color: $light-grey;
    }
}

.action.gh-btn.inline.gh-btn-blue.gh-btn-bg,.action.gh-btn.inline.gh-btn-blue.text-dark-gray{
  margin: 5px;
}
.action.gh-btn.inline.gh-btn-blue.text-dark-gray{
  padding-top: 7px;
  padding-bottom: 7px;
}
.action.gh-btn.gh-btn-blue.text-dark-gray{
    padding: 4px;


    p{
      font-size: 12px;
    }
}
.action.gh-btn.gh-btn-blue.text-dark-gray.close{
  padding: 0;
  margin: 0;
}
/* -------------------------------------------------------- */

.btn-blue,
.btn-blue-reverse,
.btn-grean,
.btn-red {
    position: relative;
    border-radius: 50px;
    color: #fff;
    padding: 8px 20px;
    line-height: 100%;
    border-width: 1px;
}

.btn-blue {
    background: $blue;
    border: 1px solid $blue;
    margin: 5px ;
}

.btn-grean {
    background: #10cfbd;
    border: 1px solid #10cfbd;
}

.btn-red {
    background: red;
    border: 1px solid red;
}

.btn-blue-reverse {
    background: none;
    border-color: $blue;
    color: #0893d2;
    border-width: 1px;
}

.btn-blue-reverse:focus,
.btn-blue-reverse:hover {
    background: $blue;
    border: 1px solid $blue;
    color: #fff;
}

.btn-blue-reverse:focus svg path,
.btn-blue-reverse:hover svg path {

    fill: #fff;
}

.btn-blue:focus,
.btn-blue:hover {
    background: none;
    border-color: $blue;
    color: $blue;
}

.btn-blue:focus svg path,
.btn-blue:hover svg path {
    fill: $blue;
}

.btn-grean:focus,
.btn-grean:hover {
    background: none;
    border-color: #10cfbd;
    color: #10cfbd;
}

.btn-red:focus,
.btn-red:hover {
    background: none;
    border-color: red;
    color: red;
}

.active-tr-sorting {
    border-bottom: 3px solid #0893d2 !important;
}

.btn-rubbish {
    padding: 6px 8px !important;
    background: none;
}

.btn-rubbish:hover {
    border-color: #90c8f8;
}

.btn-wire {
    font-size: 20px;
    font-weight: 100;
    line-height: 20px;
    margin: 0 10px 20px;
    background: none;
    border: 1px solid #0893d2;
    border-radius: 50px;
    color: #0893d2;
    padding: 10px 20px !important;
}

.btn-small {
    font-size: 17px;
    padding: 4px 8px;
}
.btn-slider-show{
    background: transparent;
    border: 2px solid #fff;
    height: 48px;
    font-size: 19px;
    color: #fff;
    margin: 10px;
    font-weight: 300;
    &:hover{
        background: #fff;
        border: 2px solid transparent;
        color: #0893d2;
    }
}
.container {
    border: 0;
}

md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 100%;
}

.gh-trans-disabl svg {
    width: 100%;
}
.gh-btn-disable{
    background-color: $light-grey !important;
    cursor: default;
}
.file-reader .cloud-keyframe.gh-cloud-keyfr {
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
}

.gh-step_contain {
    display: flex;
    justify-content: flex-start;;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
    -webkit-align-content: space-between;
    align-content: space-between;
}
.gh-step_3_table{
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
.gh-step_contain h1 {
    text-align: center;
}

.gh-cloud-keyfr svg {
    width: 65%;
}

.loader-input-list {
    border: 4px solid #f3f3f3;
    /* Light grey */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 28px;
    height: 28px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.watcher-count {
    color: red;
    border: 1px solid red;
    border-radius: 3px;
    position: absolute;
    top: 2px;
    right: 2px;
    line-height: 1;
    background-color: white;
    padding: 2px 3px;
}

.apps_page {
    min-height: 100%;
		position: relative;
		background: rgb(105,155,200);
    background: -moz-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%, rgba(181,197,216,1) 57%);
    background: -webkit-gradient(radial, top left, 0px, top left, 100%, color-stop(0%,rgba(105,155,200,1)), color-stop(57%,rgba(181,197,216,1)));
    background: -webkit-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
    background: -o-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
    background: -ms-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
    background: radial-gradient(ellipse at top left, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
}

.wrap-apps-list {
    height: 100%;

    .rubbish {
        display: inline-block;
        cursor: pointer;
        padding: 6px 8px;
        float: right;
        margin: 0 15px 0 0;
        border: 2px solid $white;
        border-radius: 50%;

        &.selected{
            border-color: $light-blue;
            path {
                fill: $light-blue;
            }
        }
        &:hover {
            border-color: $light-blue;
            background: #fff;
            path {
                fill: $light-blue;
            }
        }
    }

    .cancel {
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
        position: absolute;
        right: 18%;
        top: 10px;
        width: 35px;
        height: 35px;
        z-index: 10;

        &:hover {
            background: rgba(0, 0, 0, 0.9);
        }
        &.eye{
            left: 18%;
        }

        svg{
            width: 70%;
        }
    }

    md-tab-content {
        overflow: hidden;

        & > div {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            justify-content: space-around;
            max-width: 1400px;
            position: relative;
            margin: 0 auto;
            overflow: hidden;
        }
    }

    ul {
        @include display-flex();
        @include flex-direction( 'row');
        @include flex(1, 0, 0px);
        flex-wrap: nowrap;
        position: relative;
        width: 100%;
        max-width: 1400px;

        li {
            @include display-flex();
            @include flex(1, 0, 0%);
            @include flex-align-items( 'center');
            position: relative;
            text-align: center;
            padding: 10px;

            & > div {
                width: 100%;
                max-width: 130px;
                margin: 0 auto;
                text-overflow: ellipsis;
                white-space: nowrap;

                & > div,
                gh-application {
                    width: 100%;

                    span {
                        color: $white;
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    md-tab-item {
        color: white;
        padding: 15px;
        font-size: 50px;
        line-height: 1;

        &.md-active {
            color: #0893d2;
        }
    }

    .md-ripple-container,
    md-ink-bar {
        display: none;
    }
}

.user_options {
    background-color: transparent!important;
    text-decoration: none;

    .user {
        margin-top: 12px;
        padding: 0 30px 0 0;

        svg {
            width: 54px!important;
            height: 54px!important;

            path {
                fill: $white!important;
            }
        }

        .photo {
            float: left;
        }

        img.photo {
            width: 60px!important;
            height: 60px!important;
        }

        div.photo {
            width: 60px;
            height: 60px;
            padding: 2px;
            border: 1px solid $white;
            border-radius: 50%;
        }

        .name {
            display: inline-block;
            line-height: 60px;
            font-size: 22px;
            color: $white;
            font-weight: 200;
            margin-left: 15px;
            padding-top: 0;
        }
    }

    .bot_arrow {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
    }
}
/* ------------------------------------------------ FILTER PANEL ---------------------------------------------------- */
.filter-count-icon {
    background-color: $red;
    border-radius: 9px;
    color: $white;
    font-size: 12px;
    line-height: 17px;
    font-weight: bold;
    height: 17px;
    padding: 1px 5px 0;
    position: absolute;
    top: 2px;
    left: 8px;
}

.filter-panel {
    @include background-hex($white);
    box-shadow: 0 0 5px #a6a6a6;
    width: 300px;
    padding: 20px;
    position: fixed;
    top: 0;
    right: -300px;
    bottom: 0;
    z-index: 100;
    @include transition( 'all linear 0.3s');

    &.opened {
        right: 0;
    }

    .filter-panel-title {
        position: relative;
        padding-left: 50px;

        .header {
            position: relative;
            color: $blue;
            font-size: 24px;
            font-weight: 300;
            line-height: 40px;
        }

        .open-close-button {
            width: 40px;
            height: 40px;
            position: absolute;
            left: 0;
            top: 0;
        }
        .close-filter-button {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .add-filter-button {
        display: block;
        font-size: 20px;
        font-weight: 200;
        margin-top: 15px;
    }
}
// Modile Version Filter
@media (max-width: 767px) {
    .filter-panel{
        width: 100%;
        right: -101%;
        overflow: scroll;
    }
}
/* ------------------------------------------------ MD-SWITCH ------------------------------------------------------- */
md-switch {
    width: 44px;
    height: 27px;
    margin: 0;
    background-color: $white;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    @include transition( 'all .3s ease-out .0s');

    .md-container {
        height: 25px;
        width: 44px;
        margin: 0;

        .md-thumb-container {
            top: 0;
            width: 17px;
            @include transition( 'all .3s ease-out .0s');

            .md-thumb {
                &:after,
                &:before,
                .md-ripple-container {
                    display: none;
                }
                height: 25px;
                width: 25px;
                background-color: $white;
                @include transition( 'all .3s ease-out .0s');
            }
        }

        .md-bar {
            height: 25px;
            width: 44px;
            top: 0;
            left: 0;
            background-color: transparent;
        }
    }

    &.md-checked {
        background: #64bd63;
        border-color: #64bd63;
    }
}
/* ------------------------------------------------ MD-SLIDER ------------------------------------------------------- */
.gh-slider{
  height: 26px;
}
md-slider.gh-slider.md-default-theme.md-min[md-discrete] .md-thumb:after, md-slider.gh-slider.md-min[md-discrete] .md-thumb:after{
  border-color: $blue;
  background: $blue;
}
md-slider {
  height: 24px;
  .md-thumb-container{
    top: -26px;
  }
    .md-slider-wrapper {
        width: 100%;
        height: 100%;

        .md-track-container {
            bottom: 0;
            top: inherit;

            .md-track-ticks {
                display: none;
            }
        }
    }
}
/* -------------------------------------------- MD-INPUT-CONTAINER -------------------------------------------------- */
md-input-container {
    margin: 0;
    padding: 0;

    .md-errors-spacer {
        display: none;
    }
}
/* ------------------------------------------------ MD-CHECKBOX ----------------------------------------------------- */
md-checkbox {
    $md-checkbox-size: 20px;
    margin: 0;
    padding: 5px;

    .md-label {
        margin-left: $md-checkbox-size + 5px;
    }

    .md-container {
        width: $md-checkbox-size;
        height: $md-checkbox-size;

        .md-ripple-container {
            display: none;
        }

        .md-icon {
            width: $md-checkbox-size;
            height: $md-checkbox-size;
            left: 5px;
            border-color: $grey;

            &:after {
                @include transform( 'rotate(35deg)');
                left: 6.67px;
                top: 4.22px;
                width: 6.67px;
                height: 9.33px;
                border-width: 1px;
            }
        }

        &:after,
        &:before {
            display: none;
        }
    }

    &.md-checked {
        .md-container {
            .md-icon {
                @include background-hex($blue);
                border-color: $blue;
            }
        }
    }
}
/* ---------------------------------------------- MD-RADIO-GROUP ---------------------------------------------------- */
md-radio-group {
    &:focus {
        outline: none;
    }

    & > md-radio-button {
        & > .md-container {
            width: 20px;
            height: 20px;

            &:before,
            .md-ripple-container {
                display: none;
            }
        }

        .md-off,
        .md-on {
            @include background-hex($white);
        }

        .md-off {
            border: 2px solid $light-grey;
        }

        &.md-checked .md-off {
            @extend .md-off;
        }

        &.md-checked .md-on {
            @extend .md-off;
            border: 5px solid $blue;
            @include transform( 'scale(.9)');
        }
    }
}
/* ------------------------------------------------- MD-SLIDER ------------------------------------------------------ */
md-slider {
    .md-thumb {
        &:after {
            border-color: $blue;
            background-color: $blue;
        }
    }

    .md-sign {
        background-color: $blue!important;
        top: -16px;

        &:after {
            border-top-color: $blue;
        }
    }

    .md-track {
        background-color: $grey!important;

        &.md-track-fill {
            background-color: $blue!important;
        }
    }
}
/* ------------------------------------------------- MD-DIALOG ------------------------------------------------------ */
md-dialog {
    padding: 10px;
    form,
    md-dialog-content {
        overflow: visible;
    }
}

.md-dialog-backdrop.md-opaque {
    background-color: black;
}
/* ------------------------------------------ MD-VIRTUAL-REPEAT-SCROLLER -------------------------------------------- */
md-virtual-repeat-scroller {
    min-height: 100%;
    max-height: 100%;
}

.md-virtual-repeat-container .md-virtual-repeat-offsetter {
    min-height: 100%;
    max-height: 100%;
}
/* ------------------------------------------------- SELECT ICON ---------------------------------------------------- */
// Content of select icon in popup

.gh-ui-select-icon-list {
    padding: 15px;

    .gh-ui-select-icon {
        display: inline-block;
        padding: 2px;
        border: 1px solid $white;
        border-radius: 5px;
        margin: 2px;
        cursor: pointer;
        width: 50px;

        &:hover {
            border-color: $dark-grey;
        }

        &.--selected {
            border-color: $blue;
        }
    }
}

.gh-field-dragovered_svg {
    display: inline-block;
}

gh-calculator .resultBlock.gh-resultBlock {
    margin: -15px -25px 0;
    width: 115%;
}

.gh-edit_field_interpretation {
    cursor: pointer;
}
.gh-pattern {
    padding: 0 !important;
}

md-autocomplete md-autocomplete-wrap {
    height: auto;
}

md-autocomplete md-autocomplete-wrap input {
    height: 100%;
    border-bottom: none;
}

// .gh-file-list_item.multiple,
// .gh-file-list_item.multiple.loading-file {
//     margin-left: 17px;
// }

.gh-timeline .gh-photo_full_name .name{
  padding-top: 0;
}
.gh-photo_full_name.one_line{
  display: flex;
  flex-direction: row;
  p{
    padding-left: 5px;
  }
}

.gh-photo_full_name.one_line.align_text_center{
    align-items: center;
    padding: 0;
}

.gh-field {
    position: relative;
}

.gh-field gh-radio-button {
    padding: 0;
    margin: 0;
    text-align: center;
}

span.gh-field-name_span {
    padding-bottom: 10px;
    // align-self: center;
}

.gh-field input {
    max-width: 190px;
}

.create-apps .item.gh-transition-disabled {
    display: inline-block;
    margin: 0 5px;
}

.gh-fields-block {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    display: none;
}

.gh-tabs_active .gh-fields-block {
    display: block;
}

.gh-tabs_active {
    border-bottom: 2px solid #0893d2;
}

.gh-tabs_item {
    display: flex;
    justify-content: space-around;
}

.gh-tabs_item tab tab-heading {
    display: inline-block;
    -webkit-user-select: none;
}

.gh-tabs_item tab {
    padding: 10px 15px;
    cursor: pointer;
}

.gh-table_overf {
    padding: 0;
    vertical-align: middle;
    // font-size: 16px;
    // line-height: 18px;
    color: #626262;
    font-weight: 300;
    display: table-cell;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    // min-width: 150px;
}

.gh-csv-service_contain {
    width: 75%;
    max-width: 650px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

body.gudhub .gh-csvtable_title {
    font-family: Roboto;
    color: $blue;
    font-size: 35.81px;
    /* Приближение из-за подстановки шрифтов */
    font-weight: 100;
    width: 49%;
display: inline-block;
text-align: center;
}

.gh-csvtable_items {
    font-family: Roboto;
    color: #d5d5e3;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
}

.gh-step_bttn:hover .gh-icon_rocet svg path {
    fill: #10cfbd;
}

.gh-correspond {
    display: block;
    width: 600px;
    margin: 50px auto;
    position: relative;
    max-height: 45vh;
}
.gh-csvtable_item{
  width: 100%;
  max-height: calc(45vh - 48px);
  overflow-y: scroll;
  overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    justify-content: space-around;
}
.gh-csvtable_items.gh-first{
  line-height: 30px;
}
.gh-step_table{
  height: 45vh;
  overflow: auto;
}
.gh-step_contain.gh-step_3{
  display: block;
  max-height: 55vh;
}
.gh-csvtable_item .gh-csvtable_items{
  width: 80%;
  display: inline-block;
  float: left;
}
.gh-csvtable_element{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid $light-grey;
  margin-top: 15px;
  position: relative;
}
.gh-csvtable_select{
  position: relative;
}
div[gh-element][data-position*='left']{
  float: left;
}
.gh-csvtable_element .gh-csvtable_select:hover .gh-csvtable_x{
  display: block;
}
.gh-csvtable_element p{
  color: $dark-grey;
}
.gh-csvtable_element .md-select-value>span:not(.md-select-icon){
  color: $black;
  font-weight: 300;
}
.gh-csvtable_element md-select{
  margin: 0;
  width: 150px;
}
.gh-csv-importing_load {
    margin: auto;
    max-width: 550px;
    width: 75%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.gh-importing_wait {
    font-family: Roboto;
    color: $blue;
    font-size: 30px;
    /* Приближение из-за подстановки шрифтов */
    font-weight: 100;
    line-height: 24px;
    /* Приближение из-за подстановки шрифтов */
    text-align: center;
    width: 100%;
    margin: 50px 0;
}

.gh-icon_import {
    width: 175px!important;
    margin-top: 28px;
}

.gh-csv-importing_finish {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gh-import_aroww {
    display: block;
    width: 137px;
    border-top: 2px dotted $blue;
    -moz-border-radius: 94px / 28px;
    -webkit-border-radius: 94px / 28px;
    border-radius: 94px / 28px;
    position: relative;
    margin-top: 104px;
}

.gh-import_aroww:after {
    content: '>';
    display: block;
    position: absolute;
    color: $blue;
    top: -6px;
    right: -5px;
    font-size: 28px;
    transform: rotate(31deg);
    font-size: 30px;
}
.gh-csvtable_select{
  position: relative;
}

.gh-csvtable_x{
  position: absolute;
  top: 0;
  right: -24px;
  display: none;
  cursor: pointer;
}
.gh-correspond::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    box-shadow: -1px 2px 4px rgba(0, 0, 128, 0.1);
    top: 42px;
    left: 0;
}

body.gudhub .app_market h1 {
    color: $blue;
    font-size: 60px;
    font-weight: 100;
    margin: 35px 0;
    width: 100%;
}

.gh-step_bttn {
    font-size: 30.52px;
    font-weight: 100;
    padding: 19px 80px;
    position: relative;
    margin-top: 15px;
}

.gh-step_arrow {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    width: 20px;
    height: 20px;
    /* Rotate */
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    /* Rotate Origin */
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
    display: inline-block;
    margin: -5px 0 -3px 10px;
}

.gh-text_undicon {
    font-family: Roboto;
    color: #0893d2;
    font-size: 24px;
    /* Приближение из-за подстановки шрифтов */
    font-weight: 100;
    line-height: 24px;
    /* Приближение из-за подстановки шрифтов */
    text-align: center;
    margin: 30px 0;
}

.gh-input_values {
    font-family: Roboto;
    color: #bbbcc5;
    font-size: 11.93px;
    /* Приближение из-за подстановки шрифтов */
    font-weight: 700;
    line-height: 24px;
    /* Приближение из-за подстановки шрифтов */
    text-align: center;
}
// .gh-icon_pen{
//   display: inline-block;
// }

.gh-step_arrow_back {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    width: 20px;
    height: 20px;
    /* Rotate */
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    /* Rotate Origin */
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%;
    display: inline-block;
    margin: -5px -10px -3px 0;
}

.btn-grean:focus .gh-step_arrow,
.btn-grean:hover .gh-step_arrow {
    border-bottom: 1px solid #10cfbd;
    border-right: 1px solid #10cfbd;
}

.btn-grean:focus .gh-step_arrow_back,
.btn-grean:hover .gh-step_arrow_back {
    border-top: 1px solid #10cfbd;
    border-left: 1px solid #10cfbd;
}

.gh-fields-block.gh-display_tab {
    display: block;
}

.gh-tabs {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.gh-tabs_title {
    padding: 7px 14px;
}

.gh-active_tab {
    border-bottom: 2px solid $blue;
}

.gh-dropd_container {
    min-width: 110px;
}

.template-container .title .edit-field-icon.gh-edit-field-icon {
    top: -16px;
    right: 50px;
}

gh-input md-autocomplete {
    display: block;
    height: auto;
}

.option_column:nth-child(3) {
    max-width: 700px;
}

.gh-radio-button_contain {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: flex-start;;
}

.gh-radio-but [gh-element] {
    display: flex;
}

.gh-show_editor .gh-icon_pen {
    display: none;
}

.gh-show_editor:hover .gh-icon_pen {
    display: inline-block;
}

.gh-field_value {
    position: relative;
}

.icon.plus.gh-icon_pen.gh-icon_pen_plus.gh-icon_link_plus {
    position: relative;
    top: 0;
    right: 0;
    padding: 0 5px;
}

.icon.plus.gh-icon_pen.gh-icon_pen_plus.gh-icon_link_plus svg {
    margin: 0;
    padding: 0;
}

.gh-show_editor {
    position: relative;
}

.gh-field .gh-field_value button {
    display: none;
    position: absolute;
    top: 50%;
    right: -32px;
    margin-top: -17px;
}

.gh-field .gh-field_value:hover button {
    display: block;
}

.gh-phone .gh-icon_pen {
    position: absolute;
    top: -3px;
    right: -31px;
    padding: 1em 5px 0 5px;
}

.gh-phone .gh-icon_pen.gh-icon_pen_delete {
    top: -3px;
    right: -58px;
}

.icon.plus.gh-icon_pen.gh-icon_pen_plus {
    position: absolute;
    top: -3px;
    right: -55px;
    padding: 3px 5px 0 5px;
    cursor: pointer;
}

.icon.plus.gh-icon_pen.gh-icon_pen_plus svg {
    border-radius: 100%;
    margin: 3px 0;
    padding: 2px;
    border: 1px solid #bbbcc5;
}

.icon.plus.gh-icon_pen.gh-icon_pen_plus:hover svg {
    border: 1px solid $blue;
}

.icon.plus.gh-icon_pen.gh-icon_pen_plus:hover svg path {
    fill: $blue;

}

.gh-btn-group input[placeholder],
textarea[placeholder] {
    font-weight: 400;
}

.gh-btn-group input::-moz-placeholder,
textarea::-moz-placeholder {
    font-weight: 300;
}

.gh-btn-group input:-moz-placeholder,
textarea:-moz-placeholder {
    font-weight: 300;
}

.gh-btn-group input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    font-weight: 300;
}

.gh-field-model_email input {
    color: $blue;
}

.gh-inline_icon_text {
    display: flex;
    margin: 0;
    line-height: 24px;
}

.slab_photo gh-image {
    min-width: 100%;
    min-height: 100%;
}

.gh-slabBlocks gh-image .image.gh-inventory_img img {
    min-width: 100%;
    min-height: 100%;
    max-height: none;
    max-width: none;
}

.gh-field .gh-field_value .gh-radio-button_contain {
    justify-content: flex-start;
}
@media screen and (max-width: 695px) {
    .gh-head_nav span.title {
        display: none;
    }

    .gh-head_nav .action.gh-btn.inline.gh-btn-blue.text-dark-gray {
        padding-left: 0;
        padding-right: 0;
    }

    .gh-head_nav .action.gh-btn.gh-btn-blue.text-dark-gray {
        padding: 5px;
    }

    .wrap-apps-list ul li > div > div span,
    .wrap-apps-list ul li > div gh-application span {
        font-size: 15px;
    }
}
@media screen and (max-height: 800px) {
    .apps_page div.wrap-apps-list ul li {
        max-height: 166px;
    }
}
@media screen and (max-height: 550px) {
    .apps_page div.wrap-apps-list ul li {
        max-height: 170px;
    }
}
@media screen and (max-height: 450px) {
//   .wrap-apps-list{
//     ul{
//       li{
//         .slider_svg{
//           max-width: 45px;
//         }
//       }
//     }
//   }
  .container_slider {
    .slide_item{
      height: 100vh!important;
    }
  }

}
@media screen and (max-height: 350px) {
  .container_slider {
    .slide_item{
      height: 59vh!important;
    }
    ul.gh_slider_wrap {
        overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 380px) {
    body.gudhub .navbar-brand {
        margin-top: 2px;
        margin-left: 3px;
    }
}

.gh-string_join {
    position: relative;
}

.gh-string_join span:last-child {
    position: relative;
    top: 0;
    right: 0;
   padding-top: 4px;
}

.gh-field_email {
    display: block;
    width: 100%;
}

.gh-field_email .field-name {
    width: 20%;
    display: inline-block;
}

.gh-field_email .gh-field_input {
    display: inline-block;
    width: 70%;
}


.gh-field .gh-table-wrap {
    // height: 397px;
}
gh-input md-autocomplete md-autocomplete-wrap{
  position: relative;
}
gh-input md-autocomplete md-autocomplete-wrap:hover  button{
  display: block;
}
md-autocomplete .md-show-clear-button button{
  position: absolute;
  top: 5px;
  right: -12px;
  display: none;
}
gh-duration-text{
  padding-top: 14px;
}
gh-button {
    .button_icon {
        margin-right: 5px;
    }
    .gh-btn {
        background-color: $blue;
        &:hover {
            color: $blue;
            background-color: white;
            border-color: $blue;
        }
    }
    .button_name {
        font-weight: 400;
    }
}