@import "../../css/css3-mixins";
@import "../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* --------------------------------------------------- INPUT --------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */

.emoji-tooltip {
    position: absolute;
    z-index: 5;
    width: 100%;
    top: 100%;
    right: 0;
    display: none;
}

@media screen and (max-width: 350px) {
    emoji-picker {
        width: 100%;
        --num-columns: 6;
    }
}

emoji-picker .nav {
    overflow-y: scroll;
}

emoji-picker .indicator-wrapper {
    display: none;
}

body.gudhub input.emoji-active-input {
    width: calc(100% - 6px);
}

.emoji-button {
    position: absolute;
    right: -11px;
    bottom: 0.5em;
    background: none;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.emoji-button:hover svg path {
    fill: $blue;
}

.emoji-button svg path {
    fill: $dark-grey;
}

.emoji-tooltip.active {
    display: block;
}

gh-input:hover .emoji-button {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

body.gudhub input,body.gudhub input:focus{
      padding-bottom: 0.5em;
      padding-top: 14px;
      color: inherit;
    }
    body.gudhub input::placeholder {
        color: inherit;
        opacity: .2;
    }



    gh-input {
        display: block;
        position: relative;
    }

    /* -------------------------------------------------------------------------------------------------------------------*/
    /* --------------------------------------------------- GH-INPUT ------------------------------------------------------*/
    /* -------------------------------------------------------------------------------------------------------------------*/
    gh-input {
        display: block;;
        cursor: pointer;
        position: relative;
        width: 100%;

        & > span {
            width: 100%;
        }

        .cross,
        .edit {
            display: none;
            height: 30px;
            position: absolute;
            right: 0;
            top: 50%;
            @include transform( 'translate(0, -50%)');
        }

        .cross {
            top: 0;
            width: 30px;
            height: 100%;
            @include background-hex($white);
            @include transform( 'translate(0,0)');

            svg {
                position: absolute;
                right: 0;
                top: 50%;
                @include transform( 'translate(0, -50%)');
            }
        }

        input.gh-input {
            background: none;
            font-weight: 300;
            border-width: 0;
            border-radius: 0;
            box-shadow: none !important;
            width: 100% !important;
            border-bottom: 1px solid $light-grey;

            &.gh-input-error,
            &.gh-input-error:focus,
            &.gh-input-error:hover {
                border-color: $red;
                color: $red;
            }

            &:focus,
            &:hover {
                box-shadow: none;
                cursor: text;
                border-bottom: 1px solid $blue;
            }
        }

        &:hover {
            input.gh-input {
                border-bottom: 1px solid $blue;
            }

            .cross {
                display: block;
            }
        }

        md-input-container:after {
            display: none!important;
        }

        md-autocomplete {
            background-color: transparent;
            border-width: 0;

            md-autocomplete-wrap {
                box-shadow: none!important;

                md-progress-linear {
                    display: none;
                }

                button {
                    md-icon {
                        @include transform( 'translate3d(-50%,-50%,0) scale(1)');

                        svg {
                            path {
                                fill: $grey;
                            }
                        }

                        &:hover {
                            svg {
                                path {
                                    fill: $blue;
                                }
                            }
                        }
                    }
                }
            }
        }
        md-autocomplete[disabled]:not([md-floating-label]){
            background: transparent;
            input{
                border-bottom: 0px;
            }
        }
    }

    .gh-input {
        background: none;
        font-weight: 300;
        border-width: 0;
        border-radius: 0;
        box-shadow: none !important;
        width: 100% !important;
        border-bottom: 1px solid $light-grey;
    }

    .gh-input-small {
        padding: 2px 8px;
        margin: 10px 2px 2px;
    }

    body.gudhub {
        .md-autocomplete-suggestions-container {
            z-index: 999;
            @include background-hex($white);
        }
    }

md-input-container {
    min-width: 14.29em;
}
.gh_input_wrap{
  display: inline-block;
}
md-autocomplete input:not(.md-input){
  padding-left: 0;
  height: auto;
  font-size: 100%;
//   border-bottom: 1px solid $grey;

}
md-input-container .md-input{
  height: auto;
  line-height: 1em;
  padding-left: 0;
  padding-right: 0;
  color: inherit!important;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input{
  border-color: $blue;
}
md-input-container .md-input[disabled]{
    color: #626262;
    background-image: none;
}

md-input-container  .md-input{
    padding-top: 14px;
}

.gh-not-found-add-item {
    display: flex;
    align-items: center;
    .gh-not-found-add-item-icon {
        display: flex;
        align-items: center;
        margin-right: 5px;
        & svg{
            border-radius: 100%;
            margin: 3px 0;
            padding: 2px;
            border: 1px solid #bbbcc5;
        }
    }
  
}
gh-not-found {
    .gh-not-found-add-item-popup {
        position: absolute;
        top: 50%;
        left: 50%;
        box-shadow: 0 2px 5px 1px $dark-grey;
        transform: translateX(-50%);
        background: #fff;
        width: calc(100% - 20px);
        border-radius: 5px;
        overflow: hidden;
        z-index: 1;
        li {
            transition: 0.5s;
            padding: 0px 10px;
            &:hover {
                background: #e6e6e6;
            }
        }
        
    }
}


.md-not-found {
    overflow: visible !important;
    .md-virtual-repeat-scroller {
        overflow: visible !important;
    }
}