iterator-debugger {
  position: absolute;
  z-index: 15;
  top: 10px;
  left: 10px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  background: #fff;

  .iterator-debugger {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__run {
      margin-left: 25px;
    }
  }
}