@import "css3-mixins";
@import "constants.scss";

/*----By Default----*/
.gh-button{
  user-select: none;
  font-family: "Roboto";
  font-weight: 300;
  color: $white;
  background-color: $blue;
  border-radius: 1em;
  border: 1px solid $blue;
  padding: 0.5em 1.3em;
  margin: 0 0.5em 5px;
  display: inline-block;
  line-height: 1em;
  cursor: pointer;
  &:hover{
    background: $pure-white;
    color: $blue;
  }
  .gh-field-tex{
    padding-top: 0;
  }
}


/*-- Different sizes border-radius--*/
.gh-radius-l{
  border-radius: 1em;
}
.gh-radius-m{
  border-radius: 0.6em;
}
.gh-radius-s{
  border-radius: 0.3em;
}
/*------*/

/*-- Different sizes box-shadow--*/
.gh-shadow-s{
  box-shadow: 0px 1px 2px 0 rgba(0,0,128,0.1);
}
.gh-shadow-l{
  box-shadow: 0px 1px 2px 0px rgba(0,0,128,0.1);
}
/*------*/

/*-- Different sizes font-weight--*/
.gh-font-weight-m{
  font-weight: 300;
}
.gh-font-weight-s{
  font-weight: 100;
}
.gh-font-weight-l{
  font-weight: 500;
}
/*------*/

/*-- Different sizes font-size--*/
// .gh-font-size-l{
//   font-size: 16px;
// }
// .gh-font-size-m{
//   font-size: 14px;
// }
// .gh-font-size-s{
//   font-size: 11px;
// }
// /*------*/

/*-- Different colors--*/
.gh-color-red{
  background-color: $red;
  border-color: $red;
  &:hover{
    color: $red;
  }
}
.gh-color-green{
  background-color: $green;
  border-color: $green;
  &:hover{
    color: $green;
  }
}
.gh-color-grey{
  background-color: $grey;
  border-color: $grey;
  &:hover{
    color: $grey;
  }
}
/*------*/

.gh-button-inversed{
  color: $blue;
  background: $pure-white;
}
