@import "../../css/constants.scss";

.top_menu_help {
  position: fixed;
  top: 0px;
  border-bottom: 1px solid $light-grey;
  width: 100%;
  box-shadow: -1px 2px 4px rgba(0, 0, 128, 0.1);
  background: $pure-white;
  display: flex;
  justify-content: space-between;
  z-index: 9;

  .app_icon {
    margin-left: 40px;
  }

  ul {
    margin-right: 20px;

    li {
      float: left;
      padding: 20px;

      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}
.center{
	padding-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 960px;
	margin: 0 auto;
	h1{
		color: $blue;
	}
	p{
		padding: 10px 5px;
	}
	.domload_pdf {
		width: 960px;
		margin: 0 auto;
		padding-bottom: 10px;
		a{
			display: flex;
			color: $blue;
			text-decoration: none;
			float: left;
			span{
				line-height: 40px;
				font-size: 18px;
			}
			&:hover{
				border: none;
			}
		}
	}
	iframe{
		display: block;
		margin: 0 auto;
	}
}