@import "./../css/constants.scss";

.gh-automation-dialog-apply {
  display: inline-flex;
}
.iterator-settings {

  &__title {
    font-size: 17px;
    color: #bbbcc5;
    margin-left: 10px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    height: 40px;

    span {
      margin-right: 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__apply {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
  }

  &__tabs {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-top: 4px solid transparent;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 300;
      color: #bbbcc5;

      .icon {
        margin-right: 5px;
      }

      &.active {
        color: $blue;
        border-bottom: 4px solid $blue;

        svg {
          path{
            fill: $blue !important;
          }
        }
      }
    }
  }
}

.gudhub.gh-ui-dialog.gh-automation-dialog__popup {
  .gh-ui-dialog-content-wrap  {
    .toolbar {
      padding: 0;

      .toolbar-controls {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .content {
      .sync-evaluation {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 5px;
        margin-bottom: 10px;
      }
    }
  }
}