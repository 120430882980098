lost-connection {
	display: flex;
	align-items: center;
	justify-content: center;
}

lost-connection .internet_error-bg {
	width: 100%;
	height: 100%;
	background: rgb(0,0,0,0.8);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
lost-connection .internet_error {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
lost-connection .internet_error h2 {
	color: #d7d7d7;
	font-size: 70px;
	text-align: center;
	margin-bottom: 40px;
	margin-top: 15px;
}
lost-connection .internet_error p {
	color: #d7d7d7;
	font-size: 35px;
	text-align: center;
	margin-bottom: 50px;
	width: 70%;
}
lost-connection .internet_error .refresh {
	width: 70px;
	fill: #fff;
	cursor: pointer;
}
lost-connection .tryAgain_btn {
	padding: 8px 20px;
	border-radius: 50px;
	margin: 10px;
	color: #fff;
	border: 2px solid #fff;
	background: transparent;
	cursor: pointer;
	font-size: 25px;
}
@media all and (max-width:576px) {
	lost-connection .internet_error h2 {
		font-size: 40px;
	}
	lost-connection .internet_error p {
		font-size: 25px;
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
	}
	lost-connection .internet_error .refresh {
		width: 40px;
	}
}