@import "../css/css3-mixins";
@import "../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------ HELP ----------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
.top_menu_help {
  position: fixed;
  top: 0px;
  border-bottom: 1px solid #e7e7ee;
  width: 100%;
  box-shadow: -1px 2px 4px rgba(0, 0, 128, 0.1);
  background: #fff;
  display: flex;
  justify-content: space-between;
  z-index: 9;

  .app_icon {
    margin-left: 40px;
  }

  ul {
    margin-right: 20px;

    li {
      float: left;
      padding: 20px;

      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

.main_block {
  height: 100%;
  display: flex;
}

.help_container {
  width: 100%;
  min-height: 100%;
  background: $white;
  display: flex;
  justify-content: flex-start;
}

.help_elements {
  max-height: 100vh;
  overflow: auto;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  position: fixed;
  padding-top: 70px;

  .link {
    padding: 10px 0px 10px 75px;

    &:hover {
      background: #0893d2;
    }

    &:hover>p {
      color: #fff;
    }
  }

  .element_active {
    background: #0893d2;

    p {
      color: #fff;
    }
  }

  p {
    padding: 8px 0;
    cursor: pointer;
  }

}

.help_text {
  margin-left: 36%;
  // margin: 0 auto;
  line-height: 1.8em;
  width: 50%;
  padding-top: 70px;

  .gh_help_settings {
    p {
      margin-bottom: 20px;
    }
  }

  .help_title {
    font-size: 34px;
    line-height: 45px;
    margin: 30px 0;
    color: $blue;
    display: flex;
    align-items: center;
  }

  h3 {
    font-size: 20px;
    font-weight: 300;
  }

  .gh_help_description {
    p {
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 1.5em;
    }

    .note {
      border-left: 2px solid $blue;
      padding-left: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 1.5em;
      margin: 10px 0 10px 20px;
    }
  }

  .type_decription {
    padding-bottom: 20px;
  }
}

.help_settings {
  margin-bottom: 15px;
  margin-top: 20px;
  line-height: 36px;
  font-weight: 300;
  font-size: 26px;
}