@import "../../css/constants.scss";
apps-list {
  
  .listing_table {
    min-width: 688px;
    margin-top: 28px;
      .listing_table_head_wrapper {
        border-bottom: 2px solid $blue;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .listing_table_body_wrapper {
        max-height: 500px;
        overflow-y: auto;
        min-height: 400px;
      }
      .listing_table_column {
        .number {
          margin-right: 25px;
          color: $black;
          min-width: 16px;
        }
        .name {
          color: $black;
          font-size: 16px;
          font-weight: 300;
          margin-left: 8px;
        }
        &.w-50 {
          width: 50%;
          display: flex;
          align-items: center;
        }
        &.w-35 {
          width: 35%;
          text-align: left;
        }
        &.w-15 {
          width: 15%;
          text-align: right;
        }
      }
      .listing_table_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 11px;
        .delete_icon {
          opacity: 0;
        }
        &:hover {
          background-color: rgba(13, 153, 255, 0.08);
          .delete_icon {
            cursor: pointer;
            opacity: 1;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid $light-grey;
        }
      }
      .tab {
        color: $blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
      .gh-radio-button-item {
        cursor: default;
      }
  }
}