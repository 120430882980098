@import "/css/constants.scss";
.automation_table {
  
  .listing_table {
    width: 100%;
    margin-top: 28px;
      .listing_table_head_wrapper {
        border-bottom: 2px solid $blue;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        width: 100%;
      }
      .listing_table_body_wrapper {
        max-height: 500px;
        overflow-y: auto;
        min-height: 400px;
      }
      .listing_table_column {
        
        .show_fields {
          margin-right: 20px;
          background: $black;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          &::before {
            position: absolute;
            width: 15px;
            height: 2px;
            background-color: #fff;
            content: '';
            transform: rotate(90deg);
            top: 11px;
            left: 5px;
            transition: .3s all ease-in;
          }
          &::after {
            position: absolute;
            width: 15px;
            height: 2px;
            background-color: #fff;
            content: '';
            top: 11px;
            left: 5px;
          }
          &.opened {
            &::before {
              transform: rotate(0deg);
              transition: .3s all ease-in;
            }
          }
        }
        .name {
          color: $black;
          font-size: 16px;
          font-weight: 300;
          margin-left: 8px;
        }
        &.w-50 {
          width: 50%;
          display: flex;
          align-items: center;
        }
        &.w-25 {
          width: 25%;
        }
        &.w-35 {
          width: 35%;
          text-align: left;
        }
        &.w-15 {
          width: 15%;
        }
        &.w-5 {
          width: 5%;
        }
      }
      .listing_table_row {
        &.opened {
          .automation_fields {
            height: 100%;
            transition: .5s all ease-in;
          }
        }
        .wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 15px 11px;
          border-bottom: 1px solid $light-grey;
          cursor: pointer;
        }
        .edit_icon {
          opacity: 0;
        }
        .delete {
          opacity: 0;
        }
        .wrap_loader_dots {
          position: relative;
          left: 0;
          transform: none;
          margin-right: 20px;
          .lds-dual-ring {
            width: 25px;
            height: 25px;
            &::after {
              width: 25px;
              height: 25px;
            }
          }
        }
        
        
        .automation_fields {
          min-height: 0;
          height: 0;
          overflow: hidden;
          transition: .5s all ease-in;
          .no_automations {
            font-size: 14px;
            color: $dark-grey;
            padding-top: 15px;
            padding-left: 105px;
          }
          .automation_field {
            display: flex;
            align-items: center;
            padding: 10px 0;
            padding-left: 105px;
            border-bottom: 1px solid $light-grey;
            .field__icon {
              margin-right: 8px;
            }
            .field__name {
              font-size: 14px;
              font-weight: 400;
              color: $black;
            }
            gh-boolean {
              padding-top: 0;
            }
            &:not(:last-child) {
              border-bottom: 1px solid $light-grey;
            }
            &:hover {
              background-color: rgba(13, 153, 255, 0.08);
              .edit_icon {
                cursor: pointer;
                opacity: 1;
              }
            }
            .field_info {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      .tab {
        color: $blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
      .gh-radio-button-item {
        cursor: default;
      }
  }
}
.gh-automation-apply-button  {
  float: left;
}

@media (min-width: 992px) and (max-width: 1350px) {
  .automations_wrapper {
    max-width: 700px;
  }
}

@media screen and (max-width: 991px) {
  .automations_wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 610px) {
  .automation_table  {
    .listing_table {
      .listing_table_column {
        &.w-50 {
          width: 100%;
        }
        &.w-15 {
          width: 30%;
        }
      }
      .listing_table_row {
        .automation_fields {
          .automation_field {
            padding-left: 50px;
            .field_info {
              width: 50%;
            }
          }
        }
      }
    }
  }
}