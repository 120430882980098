@import "../../css/constants.scss";
group-users {
  .group_users {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    min-height: 95px;
    &__item {
      text-align: center;
      position: relative;
      .img_wrapper {
        position: relative;
        display: inline-block;
      }
      .group_users__name {
        opacity: 0;
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $black;
      }
      img {
        width: 78px;
        height: 78px;
        border: 6px solid #fff;
        border-radius: 50%;
      }
      &:not(:first-child) {
        margin-left: -45px;
      }
      .permission {
        position: absolute;
        display: inline-block;
        left: 8px;
        bottom: 9px;
        padding: 5px 12px;
        border-radius: 0 0 80px 80px;
        color: #fff;
        opacity: 0;
        font-size: 11px;
        font-weight: 700;
        width: 79%;
      }
      &:hover {
        z-index: 1;
        .permission {
          opacity: 1;
        }
        .group_users__name {
          opacity: 1;
        }
      }
    }
  }
}