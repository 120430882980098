.contactus_ctrl{
	overflow: hidden;
}
.contact-us_content{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;

.contact-us_title{
	font-size: 58px;
    line-height: 40px;
    font-weight: 200;
    color: #0893d2;
    margin: 30px 0;
}
.contact_us {
 label{
	font-size: 12px;
	font-family: roboto;
	font-weight: 500;
	color: #A7A9B3;
	overflow: hidden;
	text-overflow: ellipsis;
}
 div{
	margin-bottom:20px;
}
 input{
	padding: 8px 0 5px 5px;
	background: none;
	font-size: 18px;
	color: #626262;
	font-weight: 300;
	border-width: 0;
	border-radius: 0;
	border-bottom: 1px solid #edf2f7;
	box-shadow: none;
}
.contactus_error-mail{
	display: block;
	color: red;
}
 button{
	position: relative;
	border-radius: 50px;
	color: #fff;
	padding: 8px 20px;
	line-height: 100%;
	background: #0893d2;
	border: 1px solid #0893d2;
}
.contact-us_btn{
	display: flex;
	align-items: center;
	flex-direction: column;
}
 textarea{
	resize: none;
}
input.ng-invalid {
  border-bottom: 1px solid red;
}
.ng-show{
	font-size: 14px;
	text-align: left;
	color: red;
}
     .error input{
    	border-bottom: 1px solid red;
	    }
  }
}
.contact-us_finish{
	margin-top: 220px;
}