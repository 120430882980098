@import "./../css/constants.scss";

.gudhub {
  &.gh-ui-dialog {
    &.fireworks-dialog {
      &.no-background {
        .gh-ui-dialog-backdrop {
          background: none;
        }
      }
      .gh-ui-dialog-content-wrap {
        background: none;
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
        .fireworks {
          width: 100%;
          .text-content {
            p {
              text-align: center;
              font-size: 100px;
              color: #fff;
              user-select: none;
            }
          }
        }
      }
    }
  }
}


gh-automation {
  position: relative;

  .gh-automation-controls {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      cursor: pointer;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-child(1) {
        margin-right: 25px;
      }

      .text {
        color: $blue;
        margin: 0;
        font-size: 14px;
      }
    }
  }
  .node {
    &.active {
      animation-name: automationBlinking;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      border-color: red;
      @keyframes automationBlinking {
        0% {
          background: rgba(211, 47, 47, 0.8);
        }
        50% {
          background: rgba(110, 136, 255, 0.8);
        }
        100% {
          background: rgba(211, 47, 47, 0.8);
        }
      }
    }
  }

  display: block;
  min-height: 35vh;
  height: calc(100vh - 62px); // 62px - header height
  width: 100%;
  background: #EDEFF0;

  .gh-automation {

    &-settings {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;
    }

    &-render {
      width: calc(100% - 300px);
    }

  }

 gh-element[element-type='additional_settings']{
   float: left;
 }

  .add-node-button{
    display: inline-block;
    margin: 5px 5px;
    padding: 5px;
    border: 1px solid #607D8B;
    cursor: pointer;
  }

  .gh-automation-apply-button{
    float: left;
  }

  .gh-node-content{
    height: 140px;

    gh-automation-settings {
      display: inline-block;
      text-align: left;
    }

    .gh-delete-node {
      display: inline-block;
      text-align: left;
      margin-left: 95px;
    }
    .active {
        path {
          animation-name: blinkRun;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          @keyframes blinkRun {
            0% {
              fill: #d32f2f;
            }
            50% {
              fill: #fff;
            }
            100% {
              fill: #d32f2f;
            }
          }
        }
    
    }
    .automation-settings-icon{
      display: inherit;
      text-align: center;
    }

    .node-notes{
      width: 140px;
      height: 50px;
      display: block;
      overflow: hidden;
      color: #ffffff;
    }
  }

  .node{
    &.trigger, &.gh-element-node, &.quiz-node, &.smartinput, &.task{
        background: #2196F3;
        &:hover{
          background: #03A9F4;
        }
    }

    &.selected.selected-active {
      
      border: rgba(255,0,0,1);
      animation: borderBlink 1s infinite;

      @keyframes borderBlink {
        0% {
          border: 3px solid rgba(255,0,0,1);
        }
        50% {
          border: 3px solid rgba(255,0,0,0);
        }
        100% {
          border: 3px solid rgba(255,0,0,1);
        }
      }

    }

    &.selected.trigger, &.selected.gh-element-node, &.selected.quiz-node, &.selected.smartinput, &.selected.task{
      background:#00bcd4;
      border-color:#009688;
    }

    .socket{
      &.items-array{
        background:#8bc34a;
      }

      &.field-value {
        background: #29b6f6;
      }
      
      &.field-value-deleted-field {
        background: $red;

        & + .input-title {
          color: $red;
        }
      }
      
      &.object-value {
        background: #ab47bc;
      }

      &.instance {
        background: orange;
      }
    }

  }

  .send-message {
    max-width: 180px;
  }


}

.gh-automation-paste {

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__content {
    min-width: 600px;
    min-height: 300px;
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue;

    textarea {
      width: 100%;
      height: 100%;
      min-height: 300px;
      resize: none;
      padding: 10px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}


// Firework animation

.pyro>.before,
.pyro>.after {
  position: absolute;
  width: 5px;
  height: 5px;
  top: 100px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro>.after {
  animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}


@keyframes gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}