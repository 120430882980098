@import "/css/constants.scss";
.automation_table {
  &.api_table {
    .listing_table {
      .listing_table_row {
        .wrapper {
          cursor: default;
          .app_name {
            display: flex;
            align-items: center;
          }
          &:hover {
            background-color: rgba(13, 153, 255, 0.08);
            .edit_icon {
              cursor: pointer;
              opacity: 1;
            }
            .delete {
              cursor: pointer;
              opacity: 1;
            }
          }
        }
      }
    }
    .gh-api-btn-switch {
      margin-left: 5px;
      padding-top: 0;
    }
  }
}
.gh-api-credentials {
  padding: 0 20px 10px 20px;
  .gh-api-credentials-title {
    font-weight: 500;
    font-size: 16px;
    color: $blue;
  }
  .gh-api-credentials-content {
    padding: 2px 5px;
    font-weight: 400;
    cursor: copy;
  }
}
.gh-automation-debug-mode {
  float: left;
  color: $black;
  padding-top: 14px;
  margin: 0 10px 0 40px
}
.gh-automation-trigger-switch {
  float: left;
  margin-left: 30px;
  padding-top: 7px;
}
.gh-api-btn-credentials {
  float: left;
}
.gh-automation-mock {
  display: flex;
  align-items: center;
}