@import "../../css/constants.scss";
// scaling... any units
$width: 100px;

.loader {
  position: relative;
  margin: 0 auto;
  width: $width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular_loader {
  animation: rotate 2s linear infinite;
  height: 50%;
  max-height: 100px;
  transform-origin: center center;
  width: 50%;
  max-width: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.circular_loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: $light-grey;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
