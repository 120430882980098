@import "../../css/css3-mixins";
@import "../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* --------------------------------------------------- AVATAR ------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-avatar {
    display: block;

    & > div {
        cursor: pointer;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
    }

    & > div.photo {
        width: 180px;
        height: 180px;
    }

    & > div.photo {}

    .file-reader {
        border: none;
        background-color: transparent;

        .file-reader-content {
            height: 100%;

            img {
                min-height: 100%;
                min-width: 100%;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}

gh-avatar > div {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 2px #edf2f7;
    -moz-box-shadow: 0 0 0 2px #edf2f7;
    box-shadow: 0 0 0 2px #edf2f7;
}

gh-avatar input[type=file] {
    display: none;
}

gh-avatar .avatar {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

gh-avatar md-progress-circular {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
}

gh-avatar .cloud-keyframe {
    display: none;
    background-color: #FF5B5B;
    top: 0;
    left: 0;
    border-radius: 50%;
    position: absolute;
}

gh-avatar > div.hover .cloud-keyframe,
gh-avatar > div:hover .cloud-keyframe {
    display: block;
}

gh-avatar .cloud-keyframe span {
    position: absolute;
    top: 64%;
    width: 82%;
    left: 50%;
    margin-left: -41%;
    color: white;
    font-size: 13px;
    font-weight: 300;
}
// .field.gh-field.gh-field_bttn{
//   display: inline-block;
// }
gh-avatar .user-logo img {
    padding: 6px;
}

.nav.navbar-nav gh-avatar .user-logo img {
    padding: 0;
}

gh-avatar .cloud-keyframe svg {
    position: relative;
    top: 0;
}

gh-avatar .cloud-keyframe svg {
    -webkit-animation: cloud-animate-avatar 0.6s infinite alternate;
    -moz-animation: cloud-animate-avatar 0.6s infinite alternate;
    -o-animation: cloud-animate-avatar 0.6s infinite alternate;
    animation: cloud-animate-avatar 0.6s infinite alternate;
}
@-webkit-keyframes cloud-animate-avatar {
    0% {
        top: 0;
    }

    100% {
        top: 5px;
    }
}
@-moz-keyframes cloud-animate-avatar {
    0% {
        top: 0;
    }

    100% {
        top: 5px;
    }
}
@-o-keyframes cloud-animate-avatar {
    0% {
        top: 0;
    }

    100% {
        top: 5px;
    }
}
@keyframes cloud-animate-avatar {
    0% {
        top: 0;
    }

    100% {
        top: 5px;
    }
}
@media screen and (max-width: 968px) {
    .gh-form > .row > .column h2 {
        font-size: 32px;
    }
}
@media screen and (max-width: 768px) {
    .gh-auth-form .closeBtn svg {
        width: 100%;
        height: 100%;
    }
}

.context-menu{
  position: fixed;
  z-index: 999;
  padding: 15px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(78, 78, 78, .31);

  ul{
    li{
      border-bottom: 1px solid #ececec;
      padding: 10px 5px;
      font-size: 14px;
      cursor: pointer;

      &:last-child{
        border-bottom: none;
      }
    }
  }
}
