@import "../../css/css3-mixins";
@import "../../css/constants.scss";


/* ------------------------------------------------------------------------------------------------------------------ */
/* --------------------------------------------------- INPUT LIST --------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-input-list {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-wrap: wrap;

  .input-list-item
    gh-input{
      width: auto;
    }
  .list-item {
      position: relative;
      border: 1px solid $light-grey;
      color: $black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0.5em;
      .gh-input-list_name{
        div{
        // display: inline-block;
        border: none;
        transition: all .5s;
        }
      }
      &:hover{
        // border: 1px solid $blue;

        div{
          transform: translateX(-4px);
          color: $blue;
        }}
      .gh-hover_close{
        display: block;
        position: absolute;
        float: right;
        margin: 0;
        opacity: 0;
        transition: all 0.2s;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
        }
      &:hover{
        .gh-input-list_name{
        }
        .gh-hover_close{
          opacity: 1;
        }

      }
  }
  .list-item-notEditable{
    position: relative;
    border: 1px solid $light-grey;
    color: $black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5em;
  }
}
gh-input-list{
  .block_icon{
    align-self: flex-end;
    margin-bottom: 7px;
    margin-left: 10px;
      svg{
      border-radius: 50%;
      padding: 2px;
      border: 1px solid $dark-grey;
      cursor: pointer;
    }
  }
  gh-field{
    .text-not-etitable{
      padding-right: 0px;
      padding-top: 0px;
    }
  }
	/*float: left;*/
}

  .list-item:hover{
    color: $blue;
  }

// .input-list-item.multiple{
//   display: none!important;
// }
.input-list-item.multiple.show_input{
  display: inline-block!important;
}
.block_add{
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  align-self: center;
  padding-top: 14px;
}
.block_add_icon svg{
  border-radius: 100%;
  margin: 3px 0;
  padding: 2px;
  border: 1px solid $grey;
}
.block_add:hover .block_add_icon  svg{
  border: 1px solid $blue;
}
.block_add_text{
  text-transform: capitalize;
  color: $grey;
  margin-left: 5px;
}
.block_add:hover .block_add_text{
  color: $blue;
}
.block_add:hover .block_add_icon  svg path{
  fill: $blue;
}
.block_icon_delet{
  cursor: pointer;
  align-self: center;
  margin-left: 5px;
}
.block_icon_delet:hover svg path{
  fill: $blue;
}
