@import "../../css/css3-mixins";
@import "../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* --------------------------------------------------- GH-RANGE ----------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-range {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 14px;

  .inputWrapper{
    display: flex;
    align-items: center;

    input[type=range] {
      margin: 6.15px 10px 6.15px 0;
      background-color: transparent;
      -webkit-appearance: none;
      outline: none;
      border: none;
    }
    input[type=range]:focus {
      outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
      background: #0893d2;
      border: 0;
      height: 3.7px;
      cursor: pointer;
    }
    input[type=range]::-webkit-slider-thumb {
      margin-top: -6.15px;
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 0.2px solid #0893d2;
      border-radius: 50px;
      cursor: pointer;
      -webkit-appearance: none;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
      background: #0893d2;
    }
    input[type=range]::-moz-range-track {
      background: #0893d2;
      border: 0;
      height: 3.7px;
      cursor: pointer;
    }
    input[type=range]::-moz-range-thumb {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 0.2px solid #0893d2;
      border-radius: 50px;
      cursor: pointer;
    }
    input[type=range]::-ms-track {
      background: transparent;
      border-color: transparent;
      border-width: 7.15px 0;
      color: transparent;
      height: 3.7px;
      cursor: pointer;
    }
    input[type=range]::-ms-fill-lower {
      background: #0893d2;
      border: 0;
    }
    input[type=range]::-ms-fill-upper {
      background: #0893d2;
      border: 0;
    }
    input[type=range]::-ms-thumb {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 0.2px solid #0893d2;
      border-radius: 50px;
      cursor: pointer;
      margin-top: 0px;
      /*Needed to keep the Edge thumb centred*/
    }
    input[type=range]:focus::-ms-fill-lower {
      background: #0893d2;
    }
    input[type=range]:focus::-ms-fill-upper {
      background: #0893d2;
    }

    @supports (-ms-ime-align:auto) {
      /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
      input[type=range] {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
      }
    }
    
    
    .value {
      width: 75px;
      font-weight: 300;
      color: $black;

      &.no_valid {
        color: $red;
        border-color: $red;
      }
    }
  }

  .gh_range{
    width: 100%;

    .gh_range_container{
      height: 1em;
      position: relative;
      width: calc(100% - 2em);
      margin-left: 1em;

      .range_all_line{
        height: 2px;
        background: $light-grey;
        position: absolute;
        left: 0;
        width: 100%;
        top: 50%;
      }

      .gh_range_line{
        position: absolute;
        height: 2px;
        background: $blue;
        top: 50%;
      }

      .gh_range_pointer_a{
        transform: translateX(-100%);
      }

      .gh_range_pointer_a, .gh_range_pointer_b{
        position: absolute;
        border-radius: 50%;
        height: 1em;
        width: 1em;
        background: $pure-white;
        user-select: none;
        cursor: pointer;
        border: 1px solid $blue;
      }
    }

    .range_container_input{
      display: flex;
      justify-content: space-between;

      .for_point_a, .for_point_b{
        width: 75px;
        font-weight: 300;
        color: $black;

        &.no_valid{
          color: $red;
          border-color: $red;
        }
      }
    }
  }
}

