@import "../../css/css3-mixins";
@import "../../css/constants.scss";



/* ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------- DURATION ------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-duration {
  display: block;
  position: relative;
  padding-top: 14px;
}

gh-duration .duration-picker {
    position: fixed;
    background-color: $white;
    text-align: center;
    z-index: 10;
    width: 230px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.15);
    user-select: none;
}

gh-duration .time-picker {
    margin: auto;
}

gh-duration .duration-picker .form-group {
    padding: 0 8px;
    width: 70px !important;
}

gh-duration .duration-picker .form-control {
    font-size: 30px;
    font-family: 'Roboto';
    font-weight: 200;
    padding: 0;
    height: inherit;
}

gh-duration .duration-icon {
    display: flex;
    align-items: flex-end;
    margin-top: -7px;
}

gh-duration .duration-icon span {
    display: flex;
}

gh-duration .duration-picker .duration-picker-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 30px;
}

gh-duration .duration-picker .duration-picker-container .durationInput {
    width: 54px;
    text-align: center;
    font-size: 30px;
    font-family: 'Roboto';
    font-weight: 200;
}

gh-duration .duration-picker .duration-picker-container .twoDots {
    color: #0791d0;
    font-weight: 500;
    font-size: 25px;
}

gh-duration .duration-picker td {
    color: #fff;
    font-weight: 500;
    font-size: 25px;
}

gh-duration .duration-picker td button {
    font-size: 30px !important;
    font-weight: 200 !important;
    padding: 0 5px !important;
}

gh-duration .duration-picker .datepicker-buttons {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid $grey;
}

gh-duration .duration-picker .datepicker-buttons__datepicker-button {
    display: inline-block;
    background-color: transparent;
    color: $blue;
    text-shadow: none;
    font-size: 15px;
    line-height: 20px;
    font-weight: 100;
    padding: 3px 8px 0;
    border-radius: 15px;
    border: 1px solid transparent;
    opacity: 0.7;
}

gh-duration .duration-picker .datepicker-buttons__datepicker-button .input-icon {
    margin-right: 5px;
}

gh-duration .duration-picker .datepicker-buttons__datepicker-button:hover {
    opacity: 1;
}



.filter-panel interpreter[intr-src="input_list"] .gh-radio-button-item {
    border: 1px solid #3fa2cf;
    color: #5BC5F2;
    background-color: transparent;
}

gh-input-list[gh-data-type="radio_button"] .list-item {
    border: none;
    padding: 0;
}

gh-input-list[gh-data-type="radio_button"] .list-item > .cross {
    /*position: relative;
  top: 10px;
  left: -26px;*/
}

gh-input-list[gh-data-type="radio_button"] a {
    display: block;
    padding: 2px 0 0 2px;
    text-align: center;
    font-weight: 500;
}


/* ------------------------------------------------------------------------------------------------------------------ */
/* ---------------------------------------------------- DURATION ---------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
body {
    &.gudhub {
        gh-duration {
            p {
                padding: 0 0 5px 0;
                cursor: pointer;
                color: $grey;
                border-bottom: 1px solid $grey;

                &:hover {
                    border-color: $blue;
                }
            }

            svg {
                display: inline-block;
            }
        }
    }
}

/* ----------------------------- BOOLEAN ------------------------------------ */
gh-boolean {
  // padding-top: 14px;
  display: block;
  position: relative;
}
