@import "../../../css/constants.scss";
groups-table {
  .listing_table {
    min-width: 688px;
    margin-top: 28px;
      .listing_table_head_wrapper {
        border-bottom: 2px solid $blue;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .listing_table_body_wrapper {
        max-height: 538px;
        overflow-y: auto;
        .listing_table_nodata {
          min-height: 538px;
          width: 100%;
          background-color: rgba(232, 239, 244, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            color: rgba(0, 0, 0, 0.2);
            font-size: 60px;
            font-weight: 400;
            line-height: 73px;
          }
        }
      }
      .listing_table_column {
        .number {
          margin-right: 25px;
          color: $black;
        }
        .name {
          color: $black;
          font-size: 16px;
          font-weight: 300;
        }
        &.w-50 {
          width: 50%;
          display: flex;
          align-items: center;
        }
        &.w-35 {
          width: 35%;
          text-align: left;
        }
        &.w-15 {
          width: 15%;
          text-align: right;
        }
      }
      .listing_table_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 11px;
        .delete_icon {
          display: none;
        }
        &:hover {
          background-color: rgba(13, 153, 255, 0.08);
          cursor: pointer;
          .delete_icon {
            display: inline;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid $light-grey;
        }
      }
      .tab {
        color: $blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
  }
}
.delete_group-popup {
  padding: 0 110px;
  max-width: 658px;
  .dialog-share-group__title {
    margin-bottom: 41px;
    color: $black;
    font-size: 30px;
    line-height: 35px;
  }
  .info {
    color: $black;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }
}
.group-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  &__header {
    .group_name-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover {
        .edit_icon {
          opacity: 1;
          display: block;
        }
      }
      .edit_icon {
        margin-left: 5px;
        opacity: 0;
        display: none;
        position: absolute;
        right: -15px;
        top: 28px;
      }
      .group_name_controls {
        margin-top: 10px;
      }
      .group_name_input {
        padding-bottom: 5px !important;
        font-size: 48px;
        font-weight: 300;
        color: $black;
        min-width: 3ch;
        &[readonly] {
          border: none;
          background-color: transparent;
          &:hover{
            border: none!important;
          }
        }
      }
      .group_name {
        color: $black;
        font-size: 48px;
        font-weight: 300;
        line-height: 56px;
        text-align: center;
      }
    }
    .group_admins {
      &__title {
        font-size: 14px;
        color: #A4A4A8;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .close_dialog {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .group_users__title {
    font-size: 60px;
    color: $blue;
    line-height: 70px;
    font-weight: 200;
    text-align: center;
  }
}

@media (min-width: 1500px) and (max-width: 1800px) {
  div.group-popup {
    padding: 0 70px;
  }
  apps-table div.listing_table {
    min-width: 620px;
  }
  members-table div.listing_table {
    min-width: 620px;
  }
}

@media (min-width: 1500px) and (max-width: 1800px) {
  div.group-popup {
    padding: 0 70px;
  }
  apps-table div.listing_table {
    min-width: 620px;
  }
  members-table div.listing_table {
    min-width: 620px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  div.group-popup {
    padding: 0 70px;
  }
  apps-table div.listing_table {
    min-width: 520px;
  }
  members-table div.listing_table {
    min-width: 520px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  div.group-popup {
    padding: 0 70px;
    &__content {
      flex-wrap: wrap;
    }
  }
  apps-table div.listing_table, members-table div.listing_table {
    min-width: initial;
    width: 100%;
  }
  groups-table div.listing_table {
    min-width: 540px;
  }
  .group-popup {
    .group_members {
      margin-bottom: 50px;
    }
    .group_members, .apps {
      width: 100%;
    }
  } 
}

@media all and (max-width: 767px) {
  div.group-popup {
    padding: 0 15px;
    &__content {
      flex-wrap: wrap;
    }
  }
  apps-table, members-table {
    div.listing_table {
      min-width: initial;
      width: 100%;
    }
  }
  groups-table div.listing_table {
    min-width: 320px;
  }
  div.group-popup {
    .group_members {
      margin-bottom: 30px;
    }
    .group_members, .apps {
      width: 100%;
    }
    button.add_member {
      margin-top: 20px;
    }
    .group_users__title {
      font-size: 50px;
    }
    members-table, apps-table {
      .listing_table {
        .listing_table_column.w-50 {
          width: 100%;
        }
        .listing_table_column.w-35 {
          width: 28%;
        }
        .listing_table_column.w-15 {
          width: 10%;
        }
        .listing_table_body_wrapper {
          overflow-y: initial;
          min-height: auto;
          max-height: none;
        }
      }
    }
  }
  .dialog-share-group {
    min-width: initial;
  }
  div.delete_group-popup  {
    padding: 0 20px;
  }
  
  
}