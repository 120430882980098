@import "./../css/constants.scss";

.automation-container {
  height: 100%;

  &__search {
    padding: 10px 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: calc(100% - 40px); //23px icon width + 17px margin
    }

    div {
      margin-top: 4px;
    }
  }

  &__wrapper {
    height: 100%;
  }

  &__elements {
    width: 300px;
    height: calc(100% - 58px); // 58px - search height
    background: #fff;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background: $blue;
    }

    .add-node-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #ffffff;
      box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: none;
      margin: 10px 15px;
      padding: 15px;
      transition: 0.2s all;
      border: 1px solid #fff;

      &:hover {
        border: 1px solid $blue;
        transition: 0.2s all;
      }

      div[gh-icon] {
        margin-right: 20px;
      }
    }
  }
}
