@import "../../css/constants.scss";
/* ============================ GH_VIEW STYLE TEMPLATES EDITOR ============================ */
gh-view {
    display: flex;
    height: 100%;
    width: 100%;

    .ghViewContainer.ghEditor {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        gh-element{
            margin-top: -1px;
            border: 1px solid #ccccdc36;
            min-height: 40px;
            width: 100%;

        }

        .ghViewNavigation {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 5px;

            .ghViewNameInput {
                height: auto;
                font-weight: 300;
                padding: 7px 14px;
            }

            .ghViewAdd {
                width: 28px;
                height: 28px;
                cursor: pointer;
                align-items: center;
                display: flex;
            }
        }

        .ghViewContainerList {
            width: calc(100% - 10px); // -10px because margin 5px
            height: calc(100% - 100px);
            display: flex;
            flex-direction: column;
            background-color: #C5CACD;
            border: 1px solid #edeff0;
            border-radius: 5px;
            margin: 0 5px;

            // Tabs
            .gh_tabs {
                float: left;
                width: 100%;
                height: 100%;
                padding: 4px;
                border: 1px dashed #d9d9d9;
                transition: all .5s;

                &:hover {
                    border-color: #338fff;
                }

                .gh_tabs_block {
                    height: 100%;

                    .gh_tabs_align {
                        display: flex;

                        .gh_tabs_block_nav {
                            .gh_tabs_block_nav_btn {
                                color: $dark-grey;
                                font-size: 18px;
                                font-weight: 300;
                                line-height: 1;
                                text-transform: uppercase;
                                display: inline-flex;
                                padding: 5px;
                                margin-right: 30px;
                                align-items: center;
                                transition: all .3s;
                                cursor: pointer;
                                float: left;

                                input {
                                    font-size: 18px;
                                    color: $dark-grey;
                                    font-weight: 300;
                                    line-height: 1;
                                    text-transform: uppercase;
                                    max-width: 100px;
                                    padding: 5px 0 4px;
                                    border: none;

                                    &:focus {
                                        color: #0893d2;
                                    }
                                }

                                &:last-child {
                                    margin-right: 0;
                                }

                                &.--active_tab {
                                    color: #0893d2;
                                }

                                .gh_tabs_block_nav_btn_icon {
                                    display: none;
                                    margin: 0 3px;

                                    &.--delete_tab {
                                        opacity: .4;
                                        display: flex;
                                    }

                                    &.--add_tab {
                                        display: flex;
                                    }
                                }

                                .gh_tabs_block_nav_btn_title {
                                    display: none;
                                }

                                &:hover {
                                    color: #0893d2;

                                    > .gh_tabs_block_nav_btn_icon.--delete_tab {
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        .gh_tabs_block_list {
                            float: left;
                            // overflow-y: auto;
                            -webkit-overflow-scrolling: touch;
                            width: 100%;
                            height: calc(100% - 40px);
                            min-height: 40px;
                            

                            .gh_tabs_block_list_item {
                            }
                        }
                    }
                }
            }

            // Flex
            .gh_flex {
                overflow: hidden;
                height: 100%;
                width: 100%;
                padding: 4px;
                background: #C5CACD;
                border: 1px dashed #d9d9d9;
                border-radius: 4px;
                transition: all .5s;
                position: relative;

                &:hover {
                    border-color: #338fff;
                }

                &.bottom_shadow_on{
                    .gh_flex_block_list{
                        position: relative;
                    }
                }

                .gh_flex_block {
                    height: 100%;
                    width: 100%;

                    .gh_flex_block_list {
                        width: 100%;
                        display: flex;
                        height: calc(100% - 40px);
                        min-height: 40px;
                    }
                }
                &:has(.gh_dropdown) {
                    overflow: unset;
                }
            }
           

            // Float
            .gh_float {
                overflow-y: auto;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                box-sizing: border-box;
                float: left;
                padding: 4px;
                transition: all .5s;
                position: relative;

                &:hover {
                    border-color: #338fff;
                }
                &.continer_decorator {
                    border: none;
                }


                .gh_float_block {
                    height: 100%;
                    width: 100%;
                    background: rgba(255, 255, 255, .4);
                    border: 1px solid rgba(255, 255, 255, 0.25);
                    border-radius: 4px;

                    .gh_float_block_list {
                        width: 100%;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        float: left;
                        display: inline-block;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        position: relative;
                        height: calc(100% - 40px);
                        min-height: 40px;
                        .gh_float_block_list_item:has(.gh_dropdown) {
                            float: left;
                        }

                        &:has(.gh_dropdown) {
                            overflow: unset;
                        }
                    }

                    &:has(.gh_dropdown) {
                        overflow: unset;
                    }
                }
                &.margin_top{
                    margin-top: 2em;
                }
                &.margin_bottom{
                    margin-bottom: 2em;
                }
                &.padding_left{
                    padding-left: 2em;
                }
                &.padding_right{
                    padding-right: 2em;
                }

                &.align_left {
                    float: left;
                }

                &.align_center {
                    float: none;
                    margin: 0 auto;
                }
                &.align_right {
                    float: right;
                }


                &.horizont_scroll{
                    // overflow-x: auto;
                    // overflow-y: hidden;
                    -webkit-overflow-scrolling:touch;
                    scroll-behavior: smooth;
                    height: max-content;

                    .gh_float_block_list{
                        width: auto;
                        display: flex;
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        overflow-y: hidden;
                        scroll-behavior: smooth;
                        -webkit-overflow-scrolling:touch;
                        
                        p,span{
                            white-space: nowrap;
                        }
                    }
    
                    & > .float_block_template_repeat_form{
                        width: auto;
                        display: flex;
                        flex-wrap: nowrap;

                        p,span{
                            white-space: nowrap;
                        }
                    }
                }
                &:has(.gh_dropdown) {
                    overflow: unset;
                }
            }

            // Dropdown
            .gh_dropdown {
                display: flex;
                align-items: center;
                position: relative;
                // width: max-content;
                padding: 8px;
                background: rgba(255, 255, 255, 0.6);

                .edit_template_container_form {
                    min-height: 0;
                    min-width: 0;
                }

                .gh_dropdown_name {
                    color: $black;
                    font-size: 14px;
                    font-weight: 300;
                    margin-left: 5px;
                    padding: 0;
                    max-width: 100%;
                    width: 100%;
                    &:hover {
                        padding: 0;
                        border-bottom: 1px solid transparent;
                    }
                }

                .caret {
                    svg {
                        path {
                            stroke: #7f7f7f;
                            stroke-width: 25px;
                        }
                    }
                }

                .dropdown_elements {
                    position: absolute;
                    left: 0;
                    top: 49px;
                    z-index: 5;
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                    transition: .5s all ease-in;
                    background: rgba(255, 255, 255, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .drag_placeholder {
                        position: absolute;
                        z-index: 1;
                        span {
                            color: $grey;
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }

                    .container_wrapper {
                        width: 100%;
                    }
                    > .gh_float_block {
                        background: none!important;
                    }
                    .edit_template_container_form {
                        display: none;
                    }
                }

                gh-element {
                    width: 100%;
                }

                .icon_pencil_container {
                    right: -15px;
                    top: -15px;
                }

                .gh_float_block_list {
                    overflow: hidden;
                }

                &:hover {
                    .icon_pencil_container {
                        display: block;
                    }
                }
            }

            .dropdown_wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: 100%;
                &.opened {
                    .caret {
                        svg {
                            transform: rotate(180deg);
                        }
                    }

                    .dropdown_elements {
                        height: auto;
                        overflow: unset;
                        transition: .5s all ease-in;
                    }
                }
            }

            // Element
            gh-element{
                float: left;
                flex: 0 1 auto;
                justify-content: flex-start;
                align-items: flex-start;
                display: flex;
                width: 100%;
                padding: 10px 0;
                .field-wrap-name{
                  padding-right: 15px;
                  display: inline-block;
                  // line-height: 1em;
                  span.field-block-name{
                      min-width: 100px;
                      max-width: 100px;
                      white-space: nowrap;
                      text-align: right;
                      display: flex;
                      justify-content: flex-end;
                      position: relative;
                  }

                  span.field-name {
                      user-select: none;
                      padding: 0 10px 0 0;
                      font-size: 12px;
                      font-weight: 500;
                      color: #bbbcc5;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1em;
                      display: inline-block;
                      white-space: normal;
                  }
                }
            }

            gh-element[data-position*=left] {
                float: left;
                width: auto;
            }

            gh-element[data-position*=right] {
                float: right;
                width: auto;
            }

            gh-element[data-position*=center] {
                justify-content: center;
            }

            gh-element[data-position*=beetwen] {
                width: 100%;
            }

            gh-element[element-type=table] {
                height: 100%;
            }
        }

        .dndDraggingSource {
            display: none !important;
        }

        .dndPlaceholder {
            background: #0893d2;
            opacity: .2;
            padding: 0;
            margin: 0;
            width: 100%;
            // border-radius: 25px;
            min-height: 40px;
            float: left;
        }
    }
}

/* ============================ EDIT TEMPLATE SERVICE ============================ */
.edit_template_container{
    height: 100%;

    .menu{
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        ul{
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;

            li{
                display: inline-flex;
                padding: 0 5px;

                &:hover{
                    .btn_view_delete{
                        opacity: .4;
                    }
                }

                .btn_view_delete{
                    display: inline-flex;
                    opacity: 0;
                    cursor: pointer;

                    &:hover{
                        opacity: 1;
                    }
                }

                .btn_save{
                    background-color: $blue;
                    color: #fff;
                    font-weight: 400;
                    border: 1px solid $blue;
                    padding: 8px 13px;
                    border-radius: 50px;
                }
            }
        }
        .view_mode {
            display: flex;
            align-items: center;
            margin-left: 325px;
            &_item {
                &:not(:last-child) {
                    margin-right: 20px;
                }
                .mode {
                    cursor: pointer;
                }
                &.active {
                    border-bottom: 2px solid $blue;
                    path {
                        fill: $blue;
                    }
                }
            }
        }
    }

    .edit_template_container_block{
        display: flex;
        height: calc(100% - 34px);

        .edit_template_container_block_tabs{
            height: 100%;
            display: flex;

            &_navigation {
                max-width: 140px;
                width: 100%;
                overflow: hidden;
                background-color: #EDEFF0;
                transition: .4s all;
                &_toggle_btn {
                    padding: 17px 0 17px 20px;
                    cursor: pointer;
                    display: inline-block;
                    svg {
                        path {
                            stroke: $black;
                            stroke-width: 15%;
                        }
                    }
                }

                &_button{
                    color: $black;
                    font-size: 14px;
                    font-weight: 400;
                }
                &_list {
                    li {
                        padding: 11px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        &.gh-active-tab{
                            background-color: $blue;
                            path{
                                fill: #fff;
                            }
                            .edit_template_container_block_tabs_navigation_button {
                                color: #fff;
                            }
                        }
                        .tab_icon {
                            margin-right: 20px;
                        }
                    }
                }
                &.hide_nav {
                    max-width: 55px;
                    overflow: hidden;
                    transition: .4s all;
                    .edit_template_container_block_tabs_navigation_toggle_btn {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .edit_template_container_block_tabs_container{
                height: calc(100% - 32px);
                border-top: 2px solid #EDEFF0;

                .edit_template_container_block_tab{
                    display: none;
                    height: 100%;
                    flex-wrap: wrap;
                    min-width: 297px;
                    max-width: 297px;

                    &.activeTab{
                        display: flex;
                    }

                    .edit_template_container_block_tab_search{
                        display: flex;
                        width: 100%;
                        margin: 10px;
                        justify-content: flex-start;
                        align-items: center;

                        span {
                            margin-right: 5px;
                        }

                        input{
                            height: auto;
                        }
                    }

                    .edit_template_container_block_tab_elements_block{
                        height: calc(100% - 46px);
                        width: 100%;
                        padding: 0 12px;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        md-virtual-repeat-container {
                            height: 98%;
                            .md-virtual-repeat-offsetter {
                                padding: 0 5px;
                            }
                        }

                        .edit_template_container_block_tab_element{
                            display: flex;
                            width: 100%;
                            margin: 10px 0;
                            background-color: #fff;
                            border: 1px solid transparent;
                            box-shadow: 0 1px 7px rgba(0,0,0,.2);
                            border-radius: 10px;
                            padding: 10px;
                            justify-content: space-between;
                            align-items: center;
                            min-height: 70px;

                            &:hover{
                                border-color: #0893d2;
                            }

                            .edit_template_container_block_tab_element_icon_title{
                                display: flex;
                                align-items: center;

                                .edit_template_container_block_tab_element_icon{
                                    margin-right: 25px;
                                }
                            }

                            .edit_template_container_block_tab_element_existing_icon{
                                position: relative;

                                .edit_template_container_block_tab_element_existing_settings{
                                    display: none;
                                    background-color: #fff;
                                    padding: 5px 0;
                                    z-index: 10;
                                    position: absolute;
                                    box-shadow: 0 0 5px rgba(121, 121, 121, 0.61);
                                    min-width: 200px;
                                    top: 0;
                                    right: 0;

                                    li{
                                        padding: 3px;
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;

                                        span{
                                            margin-right: 5px;
                                        }

                                        &:hover{
                                            background: rgba(8, 147, 210, 0.14);
                                        }
                                    }
                                }

                                &:hover .edit_template_container_block_tab_element_existing_settings{
                                    display: block;
                                }
                            }
                            .template_info {
                                span {
                                    font-size: 11px;
                                    font-weight: 500;
                                    color: $dark-grey;
                                }
                            }
                        }
                    }
                }
            }
        }

        .edit_template_container_block_views{
            height: 100%;
            width: 100%;
            overflow: hidden;
            background: #fff;
            &_navigation {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: flex-end;
                height: 55px;
                margin-bottom: 8px;
                .btn_add {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $blue;
                    border-radius: 50%;
                    margin-left: 10px;
                    margin-bottom: 7px;
                    cursor: pointer;
                }
            }
            gh-view {
                margin: 0 auto;
                max-width: 100%;
                transition: .3s all ease-in;
                &.mobile {
                    .gh_width_percent.gh_col_width_6 {
                        width: 100%;
                    }
                }
            }
        }
        .views_list {
            display: flex;
            align-items: flex-end;
            border-radius: 4px;
            max-width: 90%;
            padding-bottom: 3px;
            height: 100%;
            &::-webkit-scrollbar {
                height: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background: #b9b9b9;
                
                &:hover {
                    background: $blue;
                }
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
              
            
            .btn_view {
                cursor: pointer;
                position: relative;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: $blue;
                border: 1px solid $blue;
                border-left: none;
                &.active_view {
                    background: $blue;
                    color: #fff;
                    border: 1px solid transparent;
                }
                &:first-child {
                    border-left: 1px solid $blue;
                }
                & > div {
                    padding: 8px 16px;
                    width: max-content;
                }
                .btn_view_edit {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #fff;
                    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, .15);
                    display: none;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: absolute;
                    right: -15px;
                    top: -15px;
                    z-index: 1;
                    .edit_settings {
                        position: fixed;
                        display: none;
                        background-color: #fff;
                        width: max-content;
                        border-radius: 7px;
                        box-shadow: 0 0 5px rgba(121, 121, 121, .6);
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            color: $blue;
                            padding: 0 7px;
                            margin: 3px 0;
                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                            &:hover {
                                background-color: $blue;
                                color: #fff;
                                svg {
                                    path {
                                        fill: #fff;
                                    }
                                }
                            }
                            span {
                                margin-right: 5px;
                            }
                        }
                    }
                    &:hover {
                        .edit_settings {
                            display: block;
                        }
                    }
                }
                &:hover {
                    .btn_view_edit {
                        display: flex;
                    }
                }
            }
        }
    }
}
.edit_view-popup {
    padding: 20px 16px 12px;
    min-width: 233px;
    &__header {
        font-size: 18px;
        color: $blue;
        font-weight: 300;
        padding-bottom: 3px;
        margin-bottom: 22px;
        border-bottom: 1px solid $blue;
    }
    &__content {
        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            label {
                color: $dark-grey;
                font-size: 12px;
                font-weight: 500;
                margin-right: 10px;
            }
            input.view_name {
                padding-top: 0;
                border-bottom: 1px solid $light-grey;
                padding-bottom: 3px;
                &::placeholder, &:hover {
                    border-color: $light-grey;
                }
                &:focus {
                    padding-top: 0;
                    padding-bottom: 3px;
                    border-color: $light-grey;
                }
            }
        }
    }
    &__btns {
        text-align: right;
    }
    .close {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
    }
    &.delete {
        padding-left: 33px;
        padding-right: 33px;
        max-width: 266px;
        .edit_view-popup__header {
            font-size: 20px;
            margin-bottom: 8px;
            border-bottom: none;
            text-align: center;
        }
        .edit_view-popup__content {
            text-align: center;
            color: $black;
            margin-bottom: 15px;
            font-size: 16px;
        }
        .edit_view-popup__btns {
            text-align: center;
        }
    }
}

.add_view_popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &__header {
        margin-top: 28px;
        margin-bottom: 12%;
        .steps {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .step {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 0 12px;
                &__number {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    border: 1px solid $dark-grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: $dark-grey;
                    }
                }
                &__title {
                    font-size: 14px;
                    color: $dark-grey;
                    margin-top: 22px;
                }
                &.active {
                    .step__number {
                        border-color: $blue;
                        span {
                            color: $blue;
                        }
                    }
                    .step__title {
                        color: $blue;
                    }
                }
            }
            .line {
                width: 88px;
                border-bottom: 2px solid $grey;
                position: absolute;
                top: 16px;
                left: 52%;
                transform: translateX(-51%);
            }
        }
    }
    &__body {
        min-width: 500px;
        .title {
            font-size: 48px;
            text-align: center;
            color: $blue;
            font-weight: 300;
        }
        .buttons {
            text-align: center;
            button {
                font-size: 30px;
                font-weight: 300;
                svg {
                    path {
                        stroke: #fff;
                        stroke-width: 30px;
                    }
                }
                &.btn-blue {
                    &:hover {
                        svg {
                            path {
                                stroke: $blue;
                            }
                        }
                    }
                }
            }
        }
        .first_step {
            &.hidden {
                transition: .7s;
                position: absolute;
                right: -10000px;
                &.animated {
                    right: 0;
                    transition: .7s;
                }
            }
        }
        .second_step {
            position: absolute;
            right: -10000px;
            top: 250px;
            transition: .7s;
            width: 100%;
            &.animated {
                transition: .7s;
                right: 0;
                &.hidden {
                    position: absolute;
                    right: -10000px;
                    transition: .7s;
                }
            }
            .input_wrapper {
                margin-top: 100px;
                margin-bottom: 188px;
                text-align: center;
                label {
                    color: #A4A4A8;
                    font-size: 18px;
                    font-weight: 500;
                    margin-right: 14px;
                }
                input {
                    font-size: 24px;
                    font-weight: 300;
                    color: $black;
                    border-bottom: 1px solid $light-grey;
                }
            }
        }
    }
    .close {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
    }
}

/* ============================ pencilContainerSettings ============================ */
.ghEditor{
    .gh_title_with_icon {
        > .continer_decorator {
           > .edit_template_container_form {
               > .edit_template_container_form_icon_title {
                    > .title_block, > .icon_block{
                        display: block!important;
                    }
                }
            }
        }
    }
    .edit_template_container_form{
        display: flex;
        justify-content: flex-end;
        min-height: 32px;
        min-width: 35px;

        .title_block{
            width: 100%;
        }

        .title_block, .icon_block{
            display: none;
        }
        .icon_block {
            max-width: 1.3em;
        }

        .edit_template_container_form_icon_title{
            display: flex;
            width: 100%;
            font-weight: 300;
            font-size: 34px;
            line-height: 40px;
            color: $blue;
            overflow: hidden;
            align-items: flex-end;
        }

        input{
            font-size: 28px;
            font-weight: 300;
            color: $blue;
            width: 100%;
        }

        .icon_pencil_container{
            display: none;
            position: absolute;
            right: 5px;
            top: 5px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #fff;
            padding: 4px;

            &:hover .icon_container{
                display: block;
                pointer-events: all;
            }

            .icon_container{
                display: none;
                pointer-events: none;
                position: fixed;
                background-color: #fff;
                padding: 5px;
                z-index: 10;
                box-shadow: 0 0 5px rgba(121, 121, 121, .61);
                width: 100%;
                max-width: 200px;
                transform: translate(-90%, -1%);

                &-item {
                    cursor: pointer;
                    padding: 3px;
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    transition: all .3s;

                    &:hover{
                        background: rgba(8, 147, 210, .14);
                    }

                    div{
                        display: flex;
                        align-items: center;
                        width: 100%;

                        span{
                            margin-right: 5px;
                        }
                    }
                }
            }
            setting-group {
                .group_name {
                    margin-right: 10px;
                }
                .setting_group {
                    li {
                        display: flex;
                        &:hover{
                            background: rgba(8, 147, 210, .14);
                        }
                    }
                    .setting_group-item {
                        li {
                            flex-wrap: wrap;
                            &:hover {
                                background: none;
                            }
                            span {
                                padding: 2px;
                                margin-right: 5px;
                                margin-bottom: 3px;
                                border: 1px solid transparent;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                &:hover {
                                    background: rgba(8, 147, 210, 0.14);
                                }
                                &.active {
                                    border-color: $red;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Flex
    .gh_flex{
        &:hover{
            > .gh_flex_block > .edit_template_container_form .icon_pencil_container{
                display: block;
            }
        }
    }

    // Float
    .gh_float{
        &:hover{
            > .gh_float_block > .edit_template_container_form .icon_pencil_container{
                display: block;
            }
        }

        &.gh_title_without_underline{
            > .gh_float_block > .gh_float_block_list{
                height: calc(100% - 44px) !important;
            }

            > .gh_float_block > .edit_template_container_form{
                .title_block{
                    display: block;
                    border: none!important;
                }
            }
        }
        &.gh_title {
            > .gh_float_block > .edit_template_container_form{
                .title_block{
                    display: block;
                    border-bottom: 1px solid $blue;
                }
            }
        }

        &.gh_title{
            > .gh_float_block > .gh_float_block_list{
                height: calc(100% - 44px) !important;
            }

            > .gh_float_block > .edit_template_container_form{
                .title_block{
                    display: block;
                    border-bottom: 1px solid $blue;
                }
            }
        }

        &.gh_icon{
            > .gh_float_block > .edit_template_container_form{
                .icon_block{
                    display: flex;
                    max-width: 1.2em;
                }
            }
        }

        &.gh_icon_left_center{
            > .gh_float_block > .edit_template_container_form{
                .icon_block{
                    display: flex;
                    max-width: 1.2em;
                }
            }
        }

        &.gh_icon_right_bottom_corner{
            position: relative;
            > .gh_float_block > .edit_template_container_form {
                .icon_block{
                    display: block!important;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    max-width: 3.2em;
                    svg path {
                        opacity: .5;
                    }
                }
            }
        }

        &.gh_icon_left_center{
            position: relative;
            .continer_decorator {
                padding-left: 6em!important;
            }
            > .gh_float_block > .edit_template_container_form {
                .icon_block{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(2%, -50%);
                    max-width: 2em!important;
                    display: block!important;
                }
            }
        }
        &.gh_title_left_icon_right {
            > .gh_float_block > .edit_template_container_form > .edit_template_container_form_icon_title {
                flex-direction: row-reverse;
                align-items: center!important;
                .icon_block {
                    display: block!important;
                }
                .title_block {
                    display: block!important;
                }
            }
        }

    }

    // Tabs
    .gh_tabs{
        &:hover{
            > .gh_tabs_block > .edit_template_container_form .icon_pencil_container{
                display: block;
            }
        }

        &.gh_title {
            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_title{
                display: inline !important;
            }
        }

        &.gh_icon{
            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_icon{
                display: flex !important;
            }
        }

        &.gh_nav_tabs_top{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: column;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                width: 100%;
                border-bottom: 1px solid #cacbd1;

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        box-shadow: inset 0 -3px 0 0 #0893d2;
                    }
                }
            }
        }

        &.gh_nav_tabs_bottom{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: column-reverse;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                width: 100%;
                border-top: 1px solid #cacbd1;

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        box-shadow: inset 0 3px 0 0 #0893d2;
                    }
                  
                }
            }
        }

        &.gh_nav_tabs_left{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: row;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                border-right: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;

                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row-reverse;

                    &.--active_tab{
                        box-shadow: inset -3px 0 0 0 #0893d2;
                    }

                    input{
                        text-align: right;
                    }
                }
            }
        }

        &.gh_nav_tabs_right{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: row-reverse;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                border-left: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;

                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row;

                    &.--active_tab{
                        box-shadow: inset 3px 0 0 0 #0893d2;
                    }
                }
            }
        }
    }
    .shadow {
        &.gh_icon_left_center {
            > .continer_decorator.gh_float{
                padding-left: 6em!important;
            }
        }
        > .continer_decorator.gh_float{
            -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.19);
            -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.19);
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.19);
            padding: 20px!important;
            
        }
    }
}

.ghForm{
    // Float
    .gh_float{
        .continer_decorator {
            width: 100%;
            height: 100%;
            padding: 0!important;
            position: relative;
           
        }
        &.gh_title_without_underline{
            > .continer_decorator {
                > .template_icon_title_block_form > .template_title_form{
                    display: block !important;
                    border: none!important;
                }
            }
        }

        &.gh_title{ 
            > .continer_decorator {
                > .template_icon_title_block_form > .template_title_form{
                    display: block !important;
                    border-bottom: 1px solid;
                }
            }
       }

        &.gh_title_with_icon{
            > .continer_decorator {
                > .template_icon_title_block_form{
                    > .template_title_form{
                        display: block !important;
                    }
                    > .template_icon_form{
                        display: flex !important;
                        max-width: 1.3em;
                    }
                }
            }
        }

        &.gh_icon{
            > .template_icon_title_block_form > .template_icon_form{
                display: flex !important;
            }
        }

        &.gh_icon_right_bottom_corner{
            position: relative;
            > .continer_decorator {
                 > .template_icon_title_block_form > .template_icon_form{
                    display: block!important;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    max-width: 3.2em!important;
                    display: block!important;
                    svg path {
                        opacity: .5;
                    }
                }
            }
        }

        &.gh_icon_left_center{
            position: relative;
            > .continer_decorator {
                padding-left: 6em!important;
                > .template_icon_title_block_form > .template_icon_form{
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(2%, -50%);
                    max-width: 2em!important;
                    display: block!important;
                }
            }
        }

        &.gh_title_left_icon_right{
           > .continer_decorator {
                > .template_icon_title_block_form{
                    flex-direction: row-reverse;
                    align-items: center!important;
                    .template_icon_form {
                        display: block!important;
                    }
                    .template_title_form {
                        display: block!important;
                    }
                }
            }
        }

        &.gh_title_without_underline, &.gh_icon{
            > .float_block_template_repeat_form{
                height: calc(100% - 41px) !important;
            }
        }
    }

    // Tabs
    .gh_tabs{
        &.gh_title{
            > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_title{
                display: inline !important;
            }
        }

        &.gh_icon{
            > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_icon{
                display: flex !important;
            }
        }

        &.gh_nav_tabs_top{
            flex-direction: column;

            > .gh_tabs_block_nav{
                width: 100%;
                // border-bottom: 1px solid #cacbd1;
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                flex-wrap: nowrap;
                -webkit-overflow-scrolling: touch;
                position: relative;

                &::after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: $grey;
                    left: 0;
                    bottom: 7px;
                    position: absolute;
                }


                span{
                    white-space: nowrap;
                }

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        position: relative;

                        &::after{
                            content: "";
                            display: block;
                            width: calc(100% - 25px);
                            height: 4px;
                            background: $blue;
                            left: 0;
                            bottom: 7px;
                            position: absolute;
                        }
                       
                    }
                }
            }
        }

        &.gh_nav_tabs_bottom{
            flex-direction: column-reverse;

            > .gh_tabs_block_nav{
                width: 100%;
                border-top: 1px solid #cacbd1;

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        box-shadow: inset 0 3px 0 0 #0893d2;
                    }
                      
                    &:before{
                        bottom: auto;
                        top: -1px;
                    }
                }
            }
        }

        &.gh_nav_tabs_left{
            flex-direction: row;

            > .gh_tabs_block_nav{
                border-right: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;


                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row-reverse;

                    &.--active_tab{
                        box-shadow: inset -3px 0 0 0 #0893d2;
                    }

                    input{
                        text-align: right;
                    }
                    &:before{
                        bottom: 0 !important;
                    }
                }
            }
        }

        &.gh_nav_tabs_right{
            flex-direction: row-reverse;

            > .gh_tabs_block_nav{
                border-left: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;

                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row;

                    &.--active_tab{
                        box-shadow: inset 3px 0 0 0 #0893d2;
                    }
                    &:before{
                        bottom: 0 !important;
                    }
                }
            }
        }
    }
    .shadow {
        &.gh_icon_left_center {
            > .continer_decorator.gh_float{
                padding-left: 6em!important;
            }
        }
        > .continer_decorator.gh_float{
            -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.19);
            -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.19);
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.19);
            padding: 20px!important;
            margin: 10px;
            
        }
    }

    .continer_decorator .gh_float{
        .template_icon_title_block_form{
            padding: 0 10px;
        }
    }
}


/* ============================ CONTAINERS STYLES ============================ */

//-- Gradient Blue
.ghViewContainer div.gh_float.gh_container_color_strawberry{
    > .gh_float.continer_decorator{ 
        background-color: #EA4492;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
    }
    .template_icon_title_block_form .template_icon_form svg path{
        fill: #fff;
    }
    .template_title_form {
        color: #fff!important;
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_strawberry .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}


//-- Gradient Red
.ghViewContainer div.gh_float.gh_container_color_pink{
    > .continer_decorator.gh_float{ 
        background-color: #ff9cda;
        color: #fff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #fff5e4;
    }
    .edit_template_container_form .title_block {
        color: #fff5e4;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_pink .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}


//-- Gradient Black
.ghViewContainer div.gh_float.gh_container_color_black{
    > .continer_decorator.gh_float{ 
        background-color: #000000;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_black .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}



//-- Violet Color
.ghViewContainer div.gh_float.gh_container_color_violet{
    > .continer_decorator.gh_float{ 
        background-color: #A358DF;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_violet .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}



//-- Red Color
.ghViewContainer div.gh_float.gh_container_color_red{
    > .continer_decorator.gh_float{ 
        background-color: #FB275D;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_red .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}


//-- Yelow Color
.ghViewContainer div.gh_float.gh_container_color_yelow{
    > .continer_decorator.gh_float{ 
        background-color: #FFCC00;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_yelow .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}


//-- Green Color
.ghViewContainer div.gh_float.gh_container_color_green{
    > .continer_decorator.gh_float{ 
        background-color: #00CC6F;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_green .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}


//-- Blue Color
.ghViewContainer div.gh_float.gh_container_color_blue{
    > .continer_decorator.gh_float{ 
        background-color: #009AFF;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_blue .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}



//-- Indigo Color
.ghViewContainer div.gh_float.gh_container_color_indigo{
    > .continer_decorator.gh_float{ 
        background-color: #595ad4;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_indigo .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}


//-- Sky Color
.ghViewContainer div.gh_float.gh_container_color_sky{
    > .continer_decorator.gh_float{ 
        background-color: #00CFF4;
        color: #ffffff;
        .icon_container {
            color: #000;
        }
        .gh_element_name{
            color: #ffffff;
        }
        .template_title_form {
            color: #fff!important;
        }
        .template_icon_title_block_form .template_icon_form svg path{
            fill: #fff;
        }
    }
    gh-element{
        color: #ffffff;
    }
    .edit_template_container_form .title_block {
        color: #ffffff;
    }
    .edit_template_container_form_icon_title {
        .icon_block {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_sky .gh_title {
    .edit_template_container_form .title_block {
        border-color: #fff!important;
    }
}




//-- Color White
.ghViewContainer div.gh_float.gh_container_color_white{
    > .continer_decorator.gh_float{ 
        background-color: #ffffff;

        > gh-element {
            color: #000;
        }
        > .gh_element_name{
            color: $dark-grey;
        }
        > .template_title_form {
            color: $blue!important;
        }
        > .icon_container {
            color: #000;
        }
        > .edit_template_container_form .title_block {
            color: $blue;
        }
        > .template_icon_title_block_form .template_icon_form svg path{
            fill: $blue;
        }
        > .edit_template_container_form_icon_title {
            .icon_block {
                svg {
                    path {
                        fill: $blue;
                    }
                }
            }
        }
    }
}
.ghViewContainer div.gh_float.gh_container_color_white .gh_title {
    .edit_template_container_form .title_block {
        border-color: $blue!important;
    }
}


/* ============================ SIZES CONTAINERS ============================ */
@for $i from 1 through 12{
    .gh_width_percent{
        &.gh_col_width_#{$i}{
            width: 100% / 12 * $i;
        }
    }

    .gh_height_percent{
        &.gh_col_height_#{$i}{
            height: 100% / 12 * $i !important;
        }
    }

    .gh_width_px{
        &.gh_col_width_#{$i}{
            max-width: 50px * $i;
            min-width: 50px * $i;
        }
    }

    .gh_height_px{
        &.gh_col_height_#{$i}{
            max-height: 60px * $i;
            min-height: 50px * $i;
        }
    }

    .ghEditor{
        .gh_height_px{
            &.gh_col_height_#{$i}{
                max-height: 100px + 50px * $i;
                min-height: 100px + 50px * $i;
            }
        }
    }
}

.ghEditor{
    .gh_height_px{
        .gh_flex_block .gh_flex_block_list gh-element{
            width: auto;
            padding: 0;
        }

        .dndPlaceholder{
            width: 100px !important;
            height: 100%;
        }
    }
}

.ghForm{
    .gh_height_px{
        .gh_float .float_block_template_repeat_form gh-element{
            width: auto;

        }
    }
}

@media (max-width: 768px) {
    // New Sizes for Adaptive
    @for $i from 1 through 12{
        .gh_float{
             .gh_tabs_block_list_item > .gh_col_width_#{$i},
            > .float_block_template_repeat_form > .gh_col_width_#{$i}{
                width: 100% !important;
            }
        }
    }
    gh-view{
        .ghViewContainer.ghForm{

            .gh_float {
                .float_block_template_repeat_form:has(.dropdown_element) {
                    position: initial;
                }
                .continer_decorator:has(.dropdown_element) {
                    position: initial;
                }
            }

            .gh_flex {
                &:has(.dropdown_element) {
                    position: relative;
                }
            }

            div.dropdown_element {
                position: initial;

                .dropdown_element_childs {
                    min-width: 100%;
                    width: 100%;

                    gh-element {
                        padding: 0 15%;
                    }
                }
            }
            
        }
        
    }
}

/* ============================ GH_VIEW STYLE TEMPLATES FORM ============================ */
gh-view{
    .ghViewContainer.ghForm{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-weight: 300;

        .gh_flex{
            height: 100%;
            //padding: 0 5px;
            display: flex;
            overflow-y: auto;
            scroll-behavior: smooth;
            //-webkit-overflow-scrolling: touch;
            &.bottom_shadow_on{
                position: relative;
            
                &::after{
                    content:'';
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    box-shadow: -1px 2px 4px rgba(0, 0, 128, 0.1);
                    border-bottom: 1px solid #e7e7ee;
                }
                
            }
            &:has(.dropdown_element) {
                overflow-y: unset;
            }
        }

        .gh_tabs {
            float: left;
            width: 100%;
            height: 100%;
            padding: 5px;
            display: flex;

            .gh_tabs_block_nav {
                .gh_tabs_block_nav_btn {
                    color: $dark-grey;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1;
                    text-transform: uppercase;
                    display: inline-flex;
                    padding: 5px 30px 15px 5px;
                    align-items: center;
                    transition: all .3s;
                    cursor: pointer;
                    float: left;
                    position: relative;

                    &::before{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $grey;
                        left: 0;
                        bottom: 7px;
                        position: absolute;
                    }

                    &:last-child {
                        // padding-right: 0;

                        &::after{
                            width: 100%;
                        }
                    }

                    &.--active_tab {
                        color: #0893d2;
                    }

                    .gh_tabs_block_nav_btn_icon {
                        display: none;
                        margin: 0 3px;
                    }

                    .gh_tabs_block_nav_btn_title {
                        display: none;
                    }

                    &:hover {
                        color: #0893d2;
                    }
                }
            }

            .gh_tabs_block_list {
                float: left;
                // overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                width: 100%;
                height: 100%;
                position: relative;
                overflow-y: auto; 

                .gh_tabs_block_list_item {
                    height: 100%;
                    width: 100%;
                    float: left;
                }
            }
        }

        .gh_float{
            // overflow-y: auto;
            // overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            //padding: 0 5px;
            box-sizing: border-box;
            float: left;
            display: flex;
            flex-wrap: wrap;

            .template_icon_title_block_form{
                display: flex;
                width: 100%;
                font-weight: 200;
                font-size: 34px;
                line-height: 40px;
                color: inherit;
                overflow: hidden;
                align-items: flex-end;

                .template_icon_form{
                    display: none;
                    max-width: 1.3em;
                }

                .template_title_form{
                    display: none;
                    //border-bottom: 1px solid;
                    margin-left: 0.3em;
                    border-color: inherit;
                    width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 300;
                    color: $blue;
                }
            }

            .float_block_template_repeat_form{
                width: 100%;
                height: 100%;
                // overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                display: inline-block;
                flex-direction: row;		
                flex-wrap: wrap;
                align-content: flex-start;
                position: relative;
              }
            
            &.margin_top{
                margin-top: 2em;
            }
            &.margin_bottom{
                margin-bottom: 2em;
            }
            &.padding_left{
                padding-left: 2em;
            }
            &.padding_right {
                padding-right: 2em;
            }
            &.align_center {
                float: none;
                margin: 0 auto;
            }
            &.align_right {
                float: right;
            }

            &.horizont_scroll{
                overflow-x: auto;
                overflow-y: hidden;
                scroll-behavior: smooth;
                -webkit-overflow-scrolling:touch;
                height: max-content;
                

                .gh_float_block_list{
                    width: auto;
                    display: flex;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    scroll-behavior: smooth;
                    -webkit-overflow-scrolling:touch; 

                    p,span{
                        white-space: nowrap;
                    }
                }

                & > .float_block_template_repeat_form{
                    width: auto;
                    display: flex;
                    flex-wrap: nowrap;

                    p,span{
                        white-space: nowrap;
                    }
                }
            }
            &.gh_icon {

                > .continer_decorator > .template_icon_title_block_form > .template_icon_form {
                    display: flex;
                    max-width: 1.3em;
                }
            }
        }

        gh-element{
            float: left;
            flex: 0 1 auto;
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            width: 100%;
            max-width: 100%;
            // padding: 0;

            gh-element{
              padding: 0;
            }
            .field-wrap-name{
              display: inline-block;
              width: 100px;
              padding-right: 15px;
              line-height: 1em;
              
              span.field-block-name{
                  min-width: 100px;
                  max-width: 100px;
                  white-space: nowrap;
                  text-align: right;
                  display: flex;
                  justify-content: flex-end;
                  position: relative;
              }

              span.field-name {
                  user-select: none;
                  padding: 0 10px 0 0;
                  font-size: 12px;
                  font-weight: 500;
                  color: $dark-grey;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1em;
                  display: inline-block;
                  white-space: normal;
              }
              &.top_position {
                width: auto;
                min-width: initial;
                max-width: none;
                line-height: inherit;
                display: flex;
            }
          }
        }

        gh-element[data-position*=left] {
            float: left;
            width: auto;
        }

        gh-element[data-position*=right] {
            float: right;
            width: auto;
        }

        gh-element[data-position*=center] {
            justify-content: center;
        }

        gh-element[data-position*=beetwen] {
            width: 100% !important;
        }

        gh-element[element-type=table] {
            height: 100%;
            padding: 0;
        }

        // Dropdown

        .dropdown_element {
            // width: max-content;
            position: relative;
            height: 100%;
            float: left;
    
            .template_icon_title_block_form {
                // width: max-content;
                align-items: center;
    
                .template_icon_form {
                    display: block;
                }
        
                .template_title_form {
                    display: block;
                    color: $dark-grey;
                    font-size: 14px;
                    font-weight: 700;
                    width: auto;
                    cursor: default;
                }
            }
    
            .caret {
                margin-left: 5px;
                display: flex;
                svg {
                    path {
                        stroke: $dark-grey;
                        stroke-width: 25px;
                    }
                }
            }
    
            &_childs {
                height: 0;
                display: none;
                background: #FFFFFF;
                box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                padding: 0 6px;
                position: absolute;
                left: 0;
                top: 50px;
                min-width: 150px;
                width: 100%;
                z-index: 5;
                
                gh-element {
                    width: 100%!important;
                    padding: 5px 0;
                    &:not(:last-child) {
                        border-bottom: 1px solid #DEE0E7;
                    }
                }
            }
            &:hover {
                .dropdown_element_childs:has(gh-element) {
                    height: auto;
                    display: block;
                }
                .caret {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
// remove scroll from form
.gh-ui-dialog .float_block_template_repeat_form {
    height: auto!important;
}
