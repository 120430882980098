task-elements-table {
  .automation_table {
    .button {
      display: inline-block;
      color: #fff;
      &.run {
        background: #0D99FF;
      }
      &.stop {
        background: #FF3333;
      }
    }
  }
}
.task-settings__toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}