@import "../../../css/constants.scss";
members-table {
  .user_selecting-wrapper  {
    padding: 5px 27px;
    border: 1px solid #0D99FF;
    border-radius: 23px;
    margin-top: 49px;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      color: $black;
      font-size: 14px;
      &:hover {
        border-color: transparent!important;
      }
      &:focus {
        border-color: transparent!important;
      }
    }
    .search {
      margin-right: 7px;
    }
    .close {
      cursor: pointer;
    }
    .gh-user_wrapper {
      display: none;
    }
    gh-user-selecting .edit-btn {
      display: none;
    }
  }
  .add_member_wrapper {
    display: flex;
    justify-content: center;
    .add_member {
      margin-top: 49px;
      font-size: 26px;
      span {
        margin-right: 5px;
      }
    }
  }
  
  .listing_table {
    min-width: 688px;
    margin-top: 28px;
      .listing_table_head_wrapper {
        border-bottom: 2px solid $blue;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .listing_table_body_wrapper {
        max-height: 500px;
        overflow-y: auto;
        min-height: 400px;
        .listing_table_nodata {
          min-height: 500px;
          width: 100%;
          background-color: rgba(232, 239, 244, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            color: rgba(0, 0, 0, 0.2);
            font-size: 60px;
            font-weight: 400;
            line-height: 73px;
          }
        }
      }
      .listing_table_column {
        .number {
          margin-right: 25px;
          color: $black;
        }
        .name {
          color: $black;
          font-size: 16px;
          font-weight: 300;
        }
        &.w-50 {
          width: 50%;
          display: flex;
          align-items: center;
        }
        &.w-35 {
          width: 35%;
          text-align: left;
        }
        &.w-15 {
          width: 15%;
          text-align: right;
        }
        .owner {
          background-color: rgb(231, 0, 97);
          color: $white;
          font-weight: 500;
          border-radius: 1em;
          border: 1px solid rgb(231, 0, 97);
          padding: 0.5em 1.3em;
          margin: 0 0.5em 5px;
          display: inline-block;
          line-height: 1em;
          cursor: pointer;
        }
      }
      .listing_table_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 11px;
        .delete_icon {
          display: none;
        }
        &:hover {
          background-color: rgba(13, 153, 255, 0.08);
          .delete_icon {
            cursor: pointer;
            display: inline;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid $light-grey;
        }
      }
      .tab {
        color: $blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
  }
}
.delete_group-popup {
  padding: 0 110px;
  max-width: 658px;
  .dialog-share-group__title {
    margin-bottom: 41px;
    color: $black;
    font-size: 30px;
    line-height: 35px;
  }
  .info {
    color: $black;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }
}
  .group-popup {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin-top: 45px;
     padding: 0 115px;
     &__header {
       .group_name {
         color: $black;
         font-size: 48px;
         font-weight: 300;
         line-height: 56px;
         text-align: center;
       }
       .group_admins {
         &__title {
           font-size: 14px;
           color: #A4A4A8;
           font-weight: 500;
           line-height: 16px;
           text-align: center;
           margin-top: 10px;
         }
       }
     }
     .close_dialog {
       position: absolute;
       right: 10px;
       top: 10px;
     }
     &__content {
       width: 100%;
       display: flex;
       justify-content: space-between;
       max-width: 1920px;
     }
  }