.team_item{
	margin: 0 25px 30px 25px;
}
.team_wrapper{
	display: flex;
	flex-wrap: wrap;
	margin-top: 100px;
	p{
		font-size: 24px;
		margin-top: 10px;
		margin-bottom: 10px;
		text-align: center;
	}
	span{
		display: block;
		text-align: center;
	}
}