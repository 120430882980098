@import '../../css/constants.scss';

$width: 25px;
$height: 15px;

$bounce_height: 30px;

.wrap_loader_dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: $width;
    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      width: 15px;
      height: $height;
      border-radius: 50%;
      background-color: #fbae17;
      transform-origin: 50%;
    }
  }
  .blue {
    &:before {
      background-color: $blue;
    }
    &:after {
      border: 3px solid $blue;
      border-color: $blue transparent $blue transparent;
    }
  }
  .light-blue {
    &:before {
      background-color: $light-blue;
    }
    &:after {
      border: 3px solid $light-blue;
      border-color: $light-blue transparent $light-blue transparent;
    }
  }
  .sky-blue {
    &:before {
      background-color: $sky-blue;
    }
    &:after {
      border: 3px solid $sky-blue;
      border-color: $sky-blue transparent $sky-blue transparent;
    }
  }
  .green {
    &:before {
      background-color: $green;
    }
    &:after {
      border: 3px solid $green;
      border-color: $green transparent $green transparent;
    }
  }
  .red {
    &:before {
      background-color: $red;
    }
    &:after {
      border: 3px solid $red;
      border-color: $red transparent $red transparent;
    }
  }
  .pure-white {
    &:before {
      background-color: $pure-white;
    }
    &:after {
      border: 3px solid $pure-white;
      border-color: $pure-white transparent $pure-white transparent;
    }
  }
  .white {
    &:before {
      background-color: $white;
    }
    &:after {
      border: 3px solid $white;
      border-color: $white transparent $white transparent;
    }
  }
  .black {
    &:before {
      background-color: $black;
    }
    &:after {
      border: 3px solid $black;
      border-color: $black transparent $black transparent;
    }
  }
  .light-grey {
    &:before {
      background-color: $light-grey;
    }
    &:after {
      border: 3px solid $light-grey;
      border-color: $light-grey transparent $light-grey transparent;
    }
  }
  .grey {
    &:before {
      background-color: $grey;
    }
    &:after {
      border: 3px solid $grey;
      border-color: $grey transparent $grey transparent;
    }
  }
  .dark-grey {
    &:before {
      background-color: $dark-grey;
    }
    &:after {
      border: 3px solid $dark-grey;
      border-color: $dark-grey transparent $dark-grey transparent;
    }
  }
  .one {
    animation: bounce 510ms alternate infinite ease;
  }
  .two {
    animation: bounce 520ms alternate infinite ease;
  }
  .three {
    animation: bounce 530ms alternate infinite ease;
  }
}

.text {
  color: #fbae17;
  display: inline-block;
  margin-left: 5px;
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

.lds-dual-ring {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 60px;
  height: 60px;
  margin: 4px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
