gh-trigger-switch, gh-api-switch{

  .edit-trigger-button{
    display: block;
    float: left;
    margin: 15px 0 0 15px;
  }

  .trigger-switch{
    float: left;
  }

}

.gh-automation-apply-button{
  float: left;
}

.gh-automation-trigger-switch{
  float: left;
  margin-left: 30px;
}

.gh-automation-target-app-dropdown{
  width: 200px;
  float: left;
  margin-left: 20px;
}

trigger-automation-debugger {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 41px;
  padding-left: 20px;
}

.activate-trigger {
  display: inline-flex;
  height: 41px;
  align-items: center;
  justify-content: flex-start;

  &__title {
    font-size: 12px;
    font-weight: 500;
    color: #bbbcc5;
    margin-right: 10px;
  }

  gh-boolean {
    padding-top: 0 !important;
    margin-left: 0;
  }
}

.trigger-name-input {
  display: inline-block;
  float: left;
}