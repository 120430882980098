@import "../../css/constants.scss";

scopes-list {
  max-width: 600px;
}

integrations-list,
scopes-list {
  
  .listing_table {
    min-width: 688px;
    margin-top: 28px;
      .listing_table_head_wrapper {
        border-bottom: 2px solid $blue;
        padding-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .listing_table_body_wrapper {
        max-height: 500px;
        overflow-y: auto;
        min-height: 400px;
      }
      .listing_table_column {
        .number {
          margin-right: 25px;
          color: $black;
          min-width: 16px;
        }
        .name {
          color: $black;
          font-size: 16px;
          font-weight: 300;
          margin-left: 8px;
          &::first-letter {
            text-transform:capitalize;
        }
        }
        .icon {
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: auto;
          }
        }
        &.w-85 {
          width: 85%;
          display: flex;
          align-items: center;
        }
        &.w-15 {
          width: 15%;
          text-align: right;
        }
        &.w-75 {
          width: 75%;
          text-align: left;
        }
        &.w-25 {
          width: 25%;
          text-align: left;
        }
      }
      .listing_table_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 11px;
        .delete_icon {
          opacity: 0;
        }
        &:hover {
          background-color: rgba(13, 153, 255, 0.08);
          .delete_icon {
            cursor: pointer;
            opacity: 1;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid $light-grey;
        }
      }
      .tab {
        color: $blue;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
      .gh-radio-button-item {
        cursor: default;
      }
  }
}

.integration-popup {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0px 20px;
  padding-top: 80px;

  scopes-list {
    margin-top: 40px;
    .listing_table {
      max-width: 688px;
      margin-left: auto;
      margin-right: auto;

      .scope-name {
        display: flex;
        align-items: center;

        .number {
          margin-right: 0;
        }
      }

      .scope-id {
        text-align: left;

        &__item {
          color: #000;
          padding: 4px 6px;
          background-color: #F2C744;
          border-radius: 4px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;

          svg {
            margin-right: 6px;
          }
        }
      }
    }
  }

  .cancel-container {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__name {
    font-weight: 200;
    font-size: 60px;
    color: #575757;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;

    span {

    &::first-letter {
      text-transform: capitalize;
    }
  }

    img {
      width: 48px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1000px) {
  integrations-list .listing_table, scopes-list .listing_table {
    min-width: 500px;
  }
}

@media screen and (max-width: 750px) {
  .integration-popup { 

    &__name {
      font-size: 24px;

      img {
        width: 24px;
      }
    }

    scopes-list {
    width: 100%;

    .listing_table {
      width: 100%;
      min-width: unset;
      max-width: unset;
      overflow-x: scroll;

      &_head_wrapper {
        width: 500px;
      }

      &_body_wrapper {
        width: 500px;
      }
    }
  }
  }
}

@media screen and (max-width: 550px) {
  .integrations {

    &_wrapper {
      width: 100%;

      integrations-list {
        width: 100%;

        .listing_table {
          min-width: unset;

          &_column {
            .number {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}