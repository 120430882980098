@import "../../css/css3-mixins";
@import "../../css/constants.scss";


/*************** GH-SWITCH *****************/
.switch {
    background: #fff;
    border: 1px solid #dfdfdf;
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    overflow: visible;
    width: 42px;
    height: 25px;
    padding: 0px;
    margin: 0px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
    transition: 0.3s ease-out all;
    -webkit-transition: 0.3s ease-out all;
    top: -1px;
    margin-top: 5px;
}
.switch.wide {
  width:80px;
}
.switch small {
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.4);
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: 0.3s ease-out all;
    -webkit-transition: 0.3s ease-out all;
}
.switch.checked {
  background: rgb(100, 189, 99);
  border-color: rgb(100, 189, 99);
}
.switch.checked small {
  left: 17px;
}
.switch.wide.checked small {
  left:52px;
}
.switch .switch-text {
  font-family:Arial, Helvetica, sans-serif;
  font-size:13px;
}

.switch .off {
  display:block;
  position: absolute;
  right: 10%;
  top: 25%;
  z-index: 0;
  color:#A9A9A9;
}

.switch .on {
  display:none;
   z-index: 0;
  color:#fff;
  position: absolute;
  top: 25%;
  left: 9%;
}

.switch.checked .off {
  display:none;
}

.switch.checked .on {
  display:block;

}

.md-virtual-repeat-container .md-virtual-repeat-sizer {
    width: 100%;

}

gh-gallery .navigation{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

gh-gallery .navigation .category{
  text-align: center;
  position: relative;
  padding: 10px 15px;
  margin: 5px 0px;
  font-size: 20px;
  align-self: center;
  border-bottom: 1px solid #d9d9d9;
}

gh-gallery .navigation .category.selected{
  border-bottom: 1px solid #0893d2;
}

gh-gallery .navigation .category.selected:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0px;
  bottom: 0px;
  background-color: #0893d2;
}

gh-gallery figure{
  overflow: hidden;
}

gh-gallery figure .info{
  display: none;
  position: absolute;
  background-color: rgba(0,0,0,0.4);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

gh-gallery figure:hover .info{
  display: block;
  cursor: pointer;
}

gh-gallery figure .info .image_name{
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: white;
  font-weight: normal;
  font-size: 16px;
  margin: 5px;
}

gh-gallery figure .info .search_icon{
  position: absolute;
  top: 30%;
  left: 30%;
  width: 40%;
}

gh-gallery figure .image,
gh-gallery figure .image div{
  width: 100%;
  height: inherit!important;
  max-height: 100%;
  min-height: 100%;
  position: relative;
  display: block;
}
md-switch.md-checked:not([disabled]){
  .md-bar{
    background-color: transparent;
  }
}