@import "../../css/css3-mixins";
@import "../../css/constants.scss";

.form-editor_editing-row {
    overflow: visible;
    border-radius: 10px;
    border: 1px solid #0893D2;
    table-layout: fixed;
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 100%;
    margin: 0;
    left: 0;

    tr {
        overflow: visible;
    }

    td {
        border-top: 0 !important;
        overflow: visible;
    }
}

.md-scroll-mask {
    position: initial;
}


/* -------------------------------------------------------------------------------------------------------------------*/
/* --------------------------------------------------- GH-Window ------------------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/
gh-window {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $white;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 10px 0 0;

    &.add_items {
        .gh-form-wrapper {
            flex: 1;
            overflow-x: hidden;
            overflow-y: auto;

            .gh-form {
                position: absolute;
            }
        }

        .add-items-footer {
            @include display-flex();
            @include flex-direction( 'row');
            @include flex(1);
            @include flex-align-items( 'center');
            max-height: 67px;
            min-height: 67px;
            padding-left: 20px;
            box-shadow: 0 0 3px rgba(0, 0, 128, 0.2);
            z-index: 2;
        }
    }

    &.app_constructor {
        background-color: #f6f7f7;

        .toolbar {
            box-shadow: none !important;
        }

        .height_on_all_container{
            height: 100%;
        }
    }

    &.edit_container,
    &.edit_field,
    &.edit_field_interpretation {
        height: 100%;
        background-color: #F0F0F3;
        @include display-flex();
        @include flex-direction( 'column');
        -webkit-justify-content: space-around;
        -ms-flex-pack: start;
        justify-content: space-around;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: distribute;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;

        .toolbar {
            @include background-hex($white);
        }

        & > div {
            -webkit-order: 0;
            -ms-flex-order: 0;
            order: 0;
            -webkit-flex: 0;
            -ms-flex: 0;
            flex: 0 0 auto;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
        }
    }

    .open_app {
        .toolbar {
            & > ul {
                & > li {
                    &:last-child {
                        -webkit-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        -webkit-justify-content: flex-end;
                        -ms-flex-pack: flex-end;
                        justify-content: flex-end;
                    }
                }
            }
        }

        .field {
            & > div {
                min-width: 100%;
            }
        }
    }

    .open_item {
        .toolbar {
            & > ul {
                & > li {
                    &:last-child {
                        -webkit-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        -webkit-justify-content: flex-end;
                        -ms-flex-pack: flex-end;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}

.gh-form_editable {
    border: 1px dashed #a0a7ad;
}

.gh-form:not(:first-child) {
    margin-top: 30px;
}

.gh-form-wrapper_editable {
    background-color: rgba(237, 239, 240, 1) !important;
}

.gh-form-wrapper_editable .sidepanel {
    background-color: #fafafa;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
}

.gh-form-wrapper_editable .sidepanel .nav-tabs {
    margin-top: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #c5c5c5;
}

.gh-form-wrapper_editable .sidepanel .nav-tabs.nav-justified > .active > a,
.gh-form-wrapper_editable .sidepanel .nav-tabs.nav-justified > .active > a:focus,
.gh-form-wrapper_editable .sidepanel .nav-tabs.nav-justified > .active > a:hover {
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid #00b0ff;
}

.gh-form-wrapper_editable .sidepanel .nav-tabs li a {
    padding: 5px 0;
    background-color: transparent;
    border: 0;
    font-size: 14px;
    border-bottom: 3px solid transparent;
}

.gh-form-wrapper_editable .sidepanel .fields-block .row {
    margin: 10px;
    padding: 0;
    background-color: transparent !important;
}

.gh-form-wrapper_editable .sidepanel .field-constructor-item {
    border: 1px solid transparent;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
    width: 100%;
    border-radius: 10px;
    padding: 10px;
}

.gh-form-wrapper_editable .sidepanel .field-constructor-item:hover {
    border: 1px solid #00b0ff;
}

.gh-form-wrapper__form-caption {
    font-size: 28px;
    line-height: 40px;
    font-weight: 200;
    color: #0893d2;
    margin-bottom: 25px;
    border-bottom: 1px solid #0893d2;
}

.gh-form-wrapper__table-settings-caption {
    font-size: 48px;
    line-height: 50px;
    font-weight: 200;
    color: #0893d2;
    margin-bottom: 25px;
}
gh-source-code {
    width: 100%;
}
